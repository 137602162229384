export interface ValueChange {
	key: string;
	original: string;
	updated: string;
}

export interface ChangeRenderHandler {
	apply: (value: ValueChange) => boolean;
	render: (value: ValueChange) => ValueChange;
}

export class ChangeRender {
	private _handlers: Array<ChangeRenderHandler> = [];

	public add(handler: ChangeRenderHandler) {
		this._handlers.push(handler);
	}

	public render(key: string, original: string, updated: string): ValueChange {
		const value = { key, original, updated };
		const handlers = this._handlers.filter((item: ChangeRenderHandler) => item.apply(value));
		if (!handlers.length) return { key, original, updated };

		return handlers.reduce((item: ValueChange, handler: ChangeRenderHandler) => handler.render(item), value);
	}
}
