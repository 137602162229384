import * as React from 'react';
import { connect } from 'react-redux';

import Select from 'antd/lib/select';

import { ItemsState } from '@common/react/store/ItemList';

import {
	itemsPageMapDispatchToProps,
	ItemsPageReduxActions,
	ItemsPageReduxProps,
} from '@app/components/Pages/ItemsPage';
import { ApplicationState } from '@app/store';
import { Gender } from '@app/objects/Pet';

const { Option } = Select;

interface OwnProps {
	disabled?: boolean;
	withFieldName?: boolean;

	handleChange: (name: string, value: number | string | undefined) => void;
	value: string | number | undefined;
}

interface PriorityItem {
	id: number;
	text: string;
	priority: number;
}

type ComponentProps = ItemsPageReduxProps<Gender> & ItemsPageReduxActions<Gender> & OwnProps;

const GenderInner = (props: ComponentProps) => {
	const {
		handleChange, disabled, value, actions, items, withFieldName,
	} = props;

	const [query, setQuery] = React.useState<string>(() => '');
	const [options, setOptions] = React.useState<Array<Gender>>(() => []);

	React.useEffect(() => {
		const text = query.toLowerCase();
		const values: Array<PriorityItem> = items.items.map((q: Gender) => ({
			id: q.id,
			text: q.name.toLowerCase(),
			priority: 0,
		}));

		values.forEach((item: PriorityItem) => {
			// Accumulate priority (should be more accurate than picking one option)
			// Because both male and female include 'ma', but 'ma' has male at the beginning
			// So should come first
			item.priority = 0;

			if (item.text === text) {
				item.priority = 10;
			}

			if (item.text.startsWith(text)) {
				item.priority += 5;
			}

			if (item.text.endsWith(text)) {
				item.priority += 5;
			}

			if (item.text.includes(text)) {
				item.priority = 1;
			}
		});

		const result = values.filter((q: PriorityItem) => q.priority > 0)
			.sort((a: PriorityItem, b: PriorityItem) => (a.priority < b.priority || a.text < b.text ? 1 : -1))
			.map((q: PriorityItem) => items.items.find((q2: Gender) => q2.id === q.id));

		setOptions(result);
	}, [query, items]);

	React.useEffect(() => {
		actions.reqPages('gender', 'genderList', 'gender', {});
	}, []);

	return (
		<div>
			{withFieldName && <span className="field-name">Gender</span>}
			<Select
				showSearch
				showArrow
				showAction={['focus', 'click']}
				placeholder="Gender"
				onChange={(value: number | string | undefined) => handleChange('genderId', value)}
				value={value}
				loading={items.isLoading}
				disabled={items.isLoading || disabled}
				onSearch={setQuery}
				filterOption={false}
			>
				<Option key={-1} value={-1} disabled>Gender</Option>
				{options.map((item) => <Option key={item.id} value={item.id} title={item.name}>{item.name}</Option>)}
			</Select>
		</div>
	);
};

export const GenderSelect = connect<ItemsPageReduxProps<Gender>, ItemsPageReduxActions<Gender>, OwnProps, ApplicationState>(
	(state: ApplicationState) => ({ items: { ...state.gender } as unknown as ItemsState<Gender> }),
	itemsPageMapDispatchToProps,
)(GenderInner);
