import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'antd/lib/grid';
import { Field, FieldProps, useFormikContext } from 'formik';

import { Nullable } from '@common/typescript/objects/Nullable';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { ApplicationState } from '@app/store';
import { PriceType } from '@app/objects/Price';
import { ServiceType } from '@app/objects/Pet';
import { PickupService } from '@app/objects/PickupService';
import { getActionCreators } from '@app/store/SelectList/ListActions';
import { FormValues } from '@app/components/Various/PriceEditor/PriceEditor';
import { PickupServiceSelect } from '@app/components/UI/Inputs/PickupServiceSelect';
import { isEqualPickupServices, isPickDefault } from '@app/store/SelectList/SelectsInterfaces';
import { ModalTable } from '@app/components/Various/PriceEditor/PriceEditorComponents/ServicePrice/ModalTable';
import { FormikField } from '@app/components/Forms/FormikField';

interface PickupServiceProps {
	serviceType: ServiceType;
	priceType: PriceType;

	clinicId: Nullable<number>;
	crematoryId: number;

	priceId: Nullable<number | string>;

	isModalOpen: boolean;
	needRefresh: boolean;
	setNeedRefresh: (value: boolean) => void;
}

export const PickupServicesComponent: React.FC<PickupServiceProps> = (props: PickupServiceProps) => {
	const {
		serviceType, priceType, clinicId, priceId,
		isModalOpen, needRefresh, setNeedRefresh,
	} = props;
	const { values } = useFormikContext<FormValues>();
	const dispatch = useDispatch();
	const store = useSelector((state: ApplicationState) => state.selects.pickupServices.items);
	const pickupService = store.find((item) => item.id === values.pickupServiceId);
	const reqSelect = React.useMemo(() =>
		bindActionCreators(getActionCreators(
			'pickupServices',
			{ endpoint: 'pickupServiceList', isEqual: isEqualPickupServices },
		), dispatch), [dispatch]);

	const reqParams = React.useMemo(() => ({
		filters: {
			priceType,
			serviceType,
			clinicId,
			availableForPricing: true,
		},
	}), [priceType, serviceType, priceId, clinicId]);

	React.useEffect(() => {
		if (!isModalOpen) return;

		if (needRefresh) {
			reqSelect.refresh(null, reqParams);
			setNeedRefresh(false);
		}

		if (values.id > 0 && store.length && !pickupService && values.specialServiceId) {
			reqSelect.refresh(null, {
				preselect: [values.pickupServiceId],
				...reqParams,
			});
		}
	}, [values.id, values.pickupServiceId, store, pickupService, needRefresh, isModalOpen]);

	return (
		<Row gutter={[30, 16]}>
			<Col xs={24} sm={24} md={priceId && Number(priceId) > 0 ? 12 : 24}>
				<FormikField
					fieldName="value"
					title="Price*"
					inputProps={{
						type: 'number',
						min: 0,
						inputMode: 'decimal',
					}}
				/>
			</Col>

			<Field name="pickupServiceId">
				{({ field, meta, form }: FieldProps<FormValues>) => {
					if (priceId && Number(priceId) > 0) {
						return (
							<Col xs={24} sm={24} md={12}>
								<FormikInput
									fieldProps={{ field, meta, form }}
									title="Pickup service*"
									containerClassName=""
									render={({ field, form }) => (
										<PickupServiceSelect
											value={field.value}
											onChange={(id?: number) => form.setFieldValue(field.name, id, false)}
											placeholder="Select a pickup service"
											pickDefault={(store: Array<PickupService>) => isPickDefault(store)}
											{...reqParams.filters}
											allowClear={false}
										/>
									)}
								/>
							</Col>
						);
					}

					return (
						<Col xs={24} sm={24}>
							<ModalTable
								endpoint="pickupServiceList"
								fieldName="pickupServiceId"
								additionalRequestParams={reqParams}
							/>
						</Col>
					);
				}}
			</Field>
		</Row>
	);
};
