import { ChangeRenderHandler, ValueChange } from '@app/components/Pages/PetEditor/OldPetEditorTabs/ChangeRender/ChangeRenderHandler';
import { toWords } from '@app/components/Utils/Utils';

export class CamelCaseRenderHandler implements ChangeRenderHandler {
	apply(value: ValueChange): boolean {
		return true;
	}

	render(value: ValueChange): ValueChange {
		return {
			key: toWords(value.key),
			original: value.original,
			updated: value.updated,
		};
	}
}
