import { ChangeRenderHandler, ValueChange } from '@app/components/Pages/PetEditor/OldPetEditorTabs/ChangeRender/ChangeRenderHandler';
import { toWords } from '@app/components/Utils/Utils';
import { DeliveryType, ServiceType } from '@app/objects/Pet';
import { PriceType } from '@app/objects/Price';

export enum EnumType {
	DeliveryType = 'DeliveryType',
	ServiceType = 'ServiceType',
	PriceType = 'PriceType',
}

interface FormatValues {
	from: string;
	to: string;
}

function format(value: ValueChange): FormatValues {
	const from = Number.parseFloat(value.original);
	const to = Number.parseFloat(value.updated);

	switch (value.key) {
	case EnumType.DeliveryType:
		return {
			from: DeliveryType[from],
			to: DeliveryType[to],
		};
	case EnumType.PriceType:
		return {
			from: PriceType[from],
			to: PriceType[to],
		};
	case EnumType.ServiceType:
		return {
			from: ServiceType[from],
			to: ServiceType[to],
		};

	default:
		return {
			from: value.original,
			to: value.updated,
		};
	}
}

export class EnumRenderHandler implements ChangeRenderHandler {
	public apply(value: ValueChange): boolean {
		return value.key === EnumType[value.key];
	}

	render(value: ValueChange): ValueChange {
		const obj = format(value);

		return {
			key: value.key,
			original: toWords(obj.from),
			updated: toWords(obj.to),
		};
	}
}
