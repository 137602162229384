import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, FilePdfOutlined } from '@ant-design/icons';
import Alert from 'antd/lib/alert';
import Button from 'antd/lib/button';
import { useFormikContext } from 'formik';

import { ApplicationState } from '@app/store';
import { useCrematory } from '@app/hooks/useCrematory';
import { ServiceType, DeliveryType } from '@app/objects/Pet';
import { Card, CardSize } from '@app/components/UI/Cards/Card';
import { ViewMode } from '@app/components/Utils/Utils';
import { activePermission, Permission } from '@app/objects/Permission';
import { FormValues } from '@app/components/Pages/ClinicPetEditor/types';
import { ResourceButton } from '@app/components/UI/Buttons/ResourceButton';
import { PetDetails } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/PetDetails';
import { getClinicPetEditPath, petListPath } from '@app/components/Pages/ClinicPetEditor/ClinicApi';
import { ProductList } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/ProductList';
import { UrnList } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/UrnList/UrnList';
import { EditorHeader } from '@app/components/UI/Header/PageHeaders/EditorHeader';
import { handleDelete } from '@app/components/UI/Table/services';
import { NotesDetails } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/NotesDetails';
import { OwnerDetails } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/OwnerDetails';
import { DocumentsList } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/DocumentsList';
import { CommunalWarning } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/CommunalWarning';
import { DeliveryDetails } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/DeliveryDetails';
import { CremationDetails } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/CremationDetails';
import { SpecialServices } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/SpecialServices/SpecialServices';

interface ClinicPetFormProps {
	mode: ViewMode;
	petId: number;
	areImportantChanges: boolean;
}

const resourceButtons = (id: number) => {
	if (id < 0) return null;

	return (
		<ResourceButton
			className="ant-btn btn btn-pdf"
			request={`/get-pet-tag?petId=${id}`}
			icon={<FilePdfOutlined />}
		>
			Print tag
		</ResourceButton>
	);
};

const DeleteCard: React.FC<Omit<ClinicPetFormProps, 'areImportantChanges'>> = ({ mode, petId }) => {
	const history = useHistory();
	const { t } = useTranslation();
	const user = useSelector((state: ApplicationState) => state.login.user);

	if (mode !== ViewMode.edit || petId === -1 || !activePermission(user, Permission.DeletePet)) return null;

	return (
		<Card
			name="Danger"
			size={CardSize.Large}

			viewContent={(
				<>
					<div style={{ gridColumn: 1 / -2 }}>Pet removal button</div>
					<div className="site-header__buttons" style={{ gridColumn: 3, justifySelf: 'end' }}>
						<Button
							title="Delete"
							onClick={() =>
								handleDelete({
									t,
									itemName: 'Pet',
									deleteEndpoint: 'deletePet',
									reload: () => history.replace('/'),
									id: petId,
								})}
							danger
							icon={<DeleteOutlined />}
						>
							Delete
						</Button>
					</div>
				</>
			)}
		/>
	);
};

export const ClinicPetForm: React.FC<ClinicPetFormProps> = (props: ClinicPetFormProps) => {
	const { values } = useFormikContext<FormValues>();
	const user = useSelector((state: ApplicationState) => state.login.user);
	const activeCrematoryId = user?.activeCrematoryId ?? -1;
	const crematory = useCrematory();

	return (
		<>
			<div className="page-container__header">
				<div className="page-container__header_clinic">
					<EditorHeader
						name="Pet"
						editPath={getClinicPetEditPath(values.id)}
						backPath={petListPath}
						viewButtons={(values.isEditable && activePermission(user, Permission.UpdatePet) ? undefined : '')}
						extraButtons={resourceButtons(props.petId)}
					/>
				</div>
				{props.areImportantChanges && (
					<Alert
						message="Important changes have been made for this pet. We recommend reprinting the tag."
						type="warning"
						style={{ marginBottom: '20px' }}
					/>
				)}
			</div>
			<div className="page-container__content">
				<CommunalWarning mode={props.mode} />

				<div className="cards-block cards-block--clinic">
					<CremationDetails mode={props.mode} crematory={crematory} />
					<PetDetails mode={props.mode} crematory={crematory} />
					<SpecialServices mode={props.mode} crematoryId={activeCrematoryId} />
					{(values.deliveryType === DeliveryType.Mail
						|| values.deliveryType === DeliveryType.HomeDropOff
						|| values.deliveryType === DeliveryType.VetDropOff) && <DeliveryDetails mode={props.mode} />}
					<OwnerDetails mode={props.mode} crematoryId={activeCrematoryId} />
					{(values.serviceType === ServiceType.Private || values.serviceType === ServiceType.SemiPrivate) && (
						<UrnList mode={props.mode} crematoryId={activeCrematoryId} />
					)}
					<ProductList mode={props.mode} crematoryId={activeCrematoryId} />
					{crematory?.clinicCanUploadFiles && <DocumentsList mode={props.mode} crematoryId={activeCrematoryId} />}
					<NotesDetails mode={props.mode} />
					<DeleteCard mode={props.mode} petId={props.petId} />
				</div>
			</div>
		</>
	);
};
