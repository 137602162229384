import * as React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { Field, FieldProps, useFormikContext } from 'formik';
import { Col, Row } from 'antd/lib/grid';

import { Nullable } from '@common/typescript/objects/Nullable';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { DiscountSelect } from '@app/components/UI/Inputs/DiscountSelect';
import {
	DiscountType,
	Price,
	PriceKind,
	PriceType,
} from '@app/objects/Price';
import {
	TextAreaResizeComponent,
} from '@app/components/Pages/PetEditor/OldPetEditor/Components/Controls/TextAreaComponent';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import { RegionSelect } from '@app/components/UI/Inputs/Address/RegionSelect';
import { addressService } from '@app/services/AddressService';
import { PostalCodeAutocomplete } from '@app/components/UI/Inputs/Address/PostalCodeAutocomplete';
import { ApplicationState } from '@app/store';
import { UserRole } from '@app/objects/User';
import { isPickDefault } from '@app/store/SelectList/SelectsInterfaces';
import { IPriceStack } from '@app/services/pricing/IPriceStack';
import { Country } from '@app/objects/Country';
import { PhoneInput } from '@app/components/UI/Inputs/PhoneInput';
import { getActionCreators } from '@app/store/SelectList/ListActions';
import { selectListRequest } from '@app/store/SelectList/SelectListRequests';
import { PostalCode } from '@app/objects/PostalCode';
import { PetPrice } from '@app/objects/Pet';
import { PickupServicePricesSelect } from '@app/components/UI/Inputs/PickupServicePricesSelect';
import { useCrematory } from '@app/hooks/useCrematory';
import { SectionContainer } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Containers/SectionContainer';
import { ControlLabel } from '@app/components/UI/ControlLabel/ControlLabel';

interface OwnerInfoSectionProps {
	country?: Nullable<string>;
	calculatedPrice: IPriceStack;
}

function setDisabledOptions(calculatedPrice: IPriceStack, item: Price) {
	const basePrice = calculatedPrice.base?.value ?? 0;
	const services = calculatedPrice.services.filter((item) => !item.removed && item.price?.specialServiceId);
	const sum = services.reduce((acc: number, current) => acc + current.value, basePrice);

	if (item.unit === DiscountType.Percentage) return false;

	return item.value > sum;
}

function toPetPrice(price: Price, petId: number, oldPrice?: PetPrice): PetPrice {
	return {
		id: oldPrice?.id ?? -1,
		clientId: oldPrice?.clientId ?? v4(),

		priceId: price.id,
		price,

		pet: null,
		petId,

		count: 1,
		completedCount: 0,
		done: false,

		value: price.value,
		extra: price.extra,
		tax: 0,
		discount: 0,

		batchCount: price.batchCount,
		batchPrice: price.batchPrice,

		name: price.name ?? 'Pickup Service',

		editor: null,
		editorId: null,

		pickupService: null,
		pickupServiceId: price.pickupServiceId,

		node: null,
		nodeId: null,

		note: '',
	};
}

function getServices(items: Array<PetPrice>, prices: Array<Price>, petId: number, id?: number) {
	const price = prices.find((i) => i.id === id);
	const pickUpService = items.find((item) => item.price?.priceKind === PriceKind.PickupPrice);
	let services: Array<PetPrice> = [];

	if (pickUpService) {
		// change already exists service
		items.forEach((item: PetPrice) => {
			if (item.price?.priceKind === PriceKind.PickupPrice) {
				if (id) {
					services.push(toPetPrice(price, petId, pickUpService));
				} else if (!id && item.id > 0) {
					services.push({ ...item, removed: true });
				}
			} else {
				services.push(item);
			}
		});
	} else {
		services = [...items, toPetPrice(price, petId)]; // add to array new service
	}

	return services;
}

export const OwnerInfoSection: React.FC<OwnerInfoSectionProps> = (props: OwnerInfoSectionProps) => {
	const { t } = useTranslation();
	const user = useSelector((state: ApplicationState) => state.login.user);
	const details = addressService.provide(props.country);
	const disabled = user?.role !== UserRole.Admin && (!user?.activeCrematoryId || user.activeCrematoryId <= 0);
	const { values } = useFormikContext<PetFormValues>();
	const [country, setCountry] = React.useState<Nullable<Country>>(null);
	const crematory = useCrematory(values.crematoryId);
	const clinicsStore = useSelector((state: ApplicationState) => state.selects.clinics.items);
	const countryStore = useSelector((state: ApplicationState) => state.selects.countries.items);
	const dispatch = useDispatch();
	const action = bindActionCreators(getActionCreators('countries', selectListRequest.countries), dispatch);
	const prices = useSelector((state: ApplicationState) => state.selects.pickupServicePrices.items);

	React.useEffect(() => {
		if (!countryStore.length) action.request(selectListRequest.countries.defaultFilters);

		if (values.priceType === PriceType.Retail || (values.priceType === PriceType.Wholesale && !values.clinicId)) {
			const value = crematory?.country;

			setCountry(countryStore.find((i) => i.alpha3 === value));
		} else {
			const countryId = clinicsStore.find((i) => i.id === values.clinicId)?.countryId;

			setCountry(countryStore.find((i) => i.id === countryId));
		}
	}, [values.priceType, values.clinicId, values.crematoryId, countryStore, clinicsStore]);

	return (
		<SectionContainer titleLocalizationKey="sections.owner">
			<Row gutter={[30, 8]}>
				<Col xs={24} sm={24} md={8}>
					<Field name="ownerFirstName">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName=""
								title={t(iKey('labels.owner-first-name'))}
							/>
						)}
					</Field>
				</Col>
				<Col xs={24} sm={24} md={8}>
					<Field name="ownerLastName">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={`${t(iKey('labels.owner-last-name'))} *`}
								containerClassName=""
							/>
						)}
					</Field>
				</Col>
				<Col xs={24} sm={24} md={8}>
					<Field name="ownerEmail">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName=""
								title={t(iKey('labels.owner-email'))}
							/>
						)}
					</Field>
				</Col>
			</Row>
			<Row gutter={[30, 8]}>
				<Col xs={24} sm={24} md={12}>
					<Field name="ownerPhone">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName=""
								title={<ControlLabel text={t(iKey('labels.owner-phone-1'))} />}
								render={({ field, form }: FieldProps<string, PetFormValues>) =>
									<PhoneInput {...field} phoneCode={country?.phoneCode} onChange={(val: string) => form.setFieldValue(field.name, val)} />}
							/>
						)}
					</Field>
				</Col>
				<Col xs={24} sm={24} md={12}>
					<Field name="ownerPhone2">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName=""
								title={t(iKey('labels.owner-phone-2'))}
								render={({ field, form }: FieldProps<string, PetFormValues>) => 	(
									<PhoneInput {...field} phoneCode={country?.phoneCode} onChange={(val: string) => form.setFieldValue(field.name, val)} />
								)}
							/>
						)}
					</Field>
				</Col>
			</Row>
			<Row gutter={[30, 8]}>
				<Col xs={24} sm={24} md={12}>
					<Field name="ownerAddress">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName=""
								title={t(iKey('labels.owner-street-address-1'))}
							/>
						)}
					</Field>
				</Col>
				<Col xs={24} sm={24} md={12}>
					<Field name="ownerAddress2">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName=""
								title={t(iKey('labels.owner-street-address-2'))}
							/>
						)}
					</Field>
				</Col>
			</Row>
			<Row gutter={[30, 8]}>
				<Col xs={24} sm={24} md={8}>
					<Field name="ownerZip">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={t(iKey(`labels.owner-${details.mailKey}`))}
								containerClassName=""
								render={(fieldProps: FieldProps<string, PetFormValues>) => (
									<PostalCodeAutocomplete
										value={fieldProps.field.value}
										onSelect={(value, item?: PostalCode) => {
											if (!item) return;

											fieldProps.form.setValues({
												...fieldProps.form.values,
												ownerZip: item.zip,
												ownerCity: item.city,
												ownerStateId: item.stateId,
											});
										}}
										country={details.country}
										disabled={disabled}
									/>
								)}
							/>
						)}
					</Field>
				</Col>

				<Col xs={24} sm={24} md={8}>
					<Field name="ownerCity">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={t(iKey('labels.owner-city'))}
								containerClassName=""
							/>
						)}
					</Field>
				</Col>

				<Col xs={24} sm={24} md={8}>
					<Field name="ownerStateId">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName=""
								title={t(iKey(`labels.owner-${details.regionKey}`))}
								render={({ field, form }) => (
									<RegionSelect
										value={field.value}
										onChange={(value?: number) => form.setFieldValue(field.name, value)}
										country={country?.alpha3 ?? details.country}
										disabled={disabled}
									/>
								)}
							/>
						)}
					</Field>
				</Col>

			</Row>
			<Row gutter={[30, 8]}>
				<Col xs={24} sm={24}>
					<Field name="ownerNotes">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={t(iKey('labels.notes-about-family'))}
								containerClassName=""
								render={({ field }: FieldProps<string, PetFormValues>) => (
									<TextAreaResizeComponent
										name={field.name}
										value={field.value}
										onChange={field.onChange}
										onBlur={field.onBlur}
									/>
								)}
							/>
						)}
					</Field>
				</Col>
			</Row>
			<Row gutter={[30, 8]}>
				<Col xs={24} sm={24} md={8}>
					<Field name="discountId">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={t(iKey('labels.discount'))}
								containerClassName=""
								render={({ field, form }) => (
									<DiscountSelect
										priceType={form.values.priceType}
										serviceType={form.values.serviceType}
										crematoryId={user?.role === UserRole.Crematory ? crematory?.id : form.values.crematoryId}
										onChange={(value) => {
											form.setFieldValue(field.name, value);
										}}
										value={form.values.discountId}
										pickDefault={(store: Array<Price>) => isPickDefault(store)}
										placeholder="Select Discount"
										disabledOptions={(item: Price) => setDisabledOptions(props.calculatedPrice, item)}
										disabled={!form.values.crematoryId || form.values.crematoryId < 0}
									/>
								)}
							/>
						)}
					</Field>
				</Col>
				<Col xs={24} sm={24} md={8}>
					<Field name="services">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={t(iKey('labels.pick-up-service'))}
								containerClassName=""
								render={({ field, form }: FieldProps<Array<PetPrice>, PetFormValues>) => (
									<PickupServicePricesSelect
										value={field.value.find((i) => i.price?.priceKind === PriceKind.PickupPrice && !i.removed)?.priceId}
										onChange={(id?: number) => {
											const services = getServices(field.value, prices, values.id, id);

											form.setFieldValue(field.name, services);
										}}
										placeholder="Select Pickup Service"
										serviceType={values.serviceType}
										priceType={values.priceType}
										crematoryId={form.values.crematoryId}
										availableForPricing={false}
									/>
								)}
							/>
						)}
					</Field>
				</Col>
			</Row>
		</SectionContainer>
	);
};
