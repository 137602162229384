import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Nullable } from '@common/typescript/objects/Nullable';

import { onChangeValue } from '@app/components/UI/Filters/FiltersList';
import {
	DeliveryType, HasIssues, PetOnHold, PetSource,
} from '@app/objects/Pet';
import {
	decodeSearchParams, encodeSearchParams, getPageFilters, getSideFilters,
} from '@app/components/Utils/Utils';

export interface DateRange {
	from: number | undefined;
	to: number | undefined;
}

export type MultipleValue = Array<number>;
type DefaultValue = number;
export type Value = Record<string, unknown> extends { multiple: true } ? MultipleValue : DefaultValue;

export interface Filters {
	clinic: MultipleValue;
	clinicType: MultipleValue;
	crematories: MultipleValue;
	crematoryRole: Value;
	contactPerson: string;
	category: MultipleValue,
	deliveryType: DeliveryType;
	date: DateRange;
	dueDate: DateRange;
	done: Value,
	discountStatus: number;
	email: string;
	excludingStatus: MultipleValue;
	enabled: number;
	hasIssues: HasIssues;
	id: string;
	internalId; string;
	invoiceNumber: string;
	invoiceStatus: number;
	isSpecial: Nullable<boolean>;
	isCustomer: Nullable<number>,
	itemType: MultipleValue,
	inventoryItemIds: MultipleValue,
	isLinkedToQuickbooks: Value,
	machineId: MultipleValue,
	myPets: boolean;
	name: string;
	orderNumber: string;
	ownerFirstName: string;
	ownerLastName: string;
	onHold: PetOnHold;
	petSpecie: number;
	petSource: PetSource;
	petBreed: MultipleValue;
	phone: string;
	priceType: MultipleValue;
	priceTypeWithAll: Value;
	priceKind: MultipleValue,
	products: MultipleValue,
	query: string;
	quantity: Value,
	role: Value;
	route: MultipleValue | Value,
	rush: Nullable<boolean>;
	serviceType: MultipleValue;
	serviceTypeWithAll: Value;
	status: MultipleValue;
	specialServices: MultipleValue;
	statusFunction: Value,
	user: MultipleValue,
	urns: MultipleValue,
	zip: string,
}

export const filterKeys: Array<keyof Filters> = ['name', 'id', 'query', 'ownerFirstName', 'ownerLastName', 'crematories', 'contactPerson', 'petSpecie',
	'petBreed', 'clinic', 'deliveryType', 'email', 'phone', 'role', 'crematoryRole', 'serviceType', 'serviceTypeWithAll', 'status', 'excludingStatus',
	'invoiceNumber', 'date', 'priceType', 'discountStatus', 'specialServices', 'enabled', 'invoiceStatus', 'orderNumber', 'dueDate', 'petSource',
	'hasIssues', 'rush', 'onHold', 'isSpecial', 'priceTypeWithAll', 'myPets', 'user', 'internalId', 'statusFunction', 'category', 'isCustomer',
	'route', 'itemType', 'machineId', 'priceKind', 'isLinkedToQuickbooks', 'quantity', 'products', 'urns', 'done', 'inventoryItemIds', 'clinicType',
	'zip',
];

export const useFilters = () :[
	Partial<Filters>,
	(name: string, value: onChangeValue) => void,
	(i: Partial<Filters>) => void,
	Partial<Filters>, () => void,
	(fieldName: string, type: string, id?: number) => void,
] => {
	const history = useHistory();
	const location = useLocation();
	const [filters, setFilters] = React.useState<Partial<Filters>>(getPageFilters(decodeSearchParams(location.search)));
	const pathname = history.location.pathname;
	const query = location.search;
	const queryParams = React.useMemo(() => decodeSearchParams(query), [query]);

	React.useEffect(() => setFilters(getPageFilters(decodeSearchParams(location.search))), [location.search]);

	const onChange = (name: string, value: onChangeValue) => setFilters((s) => {
		if (name === 'date' && Array.isArray(value)) {
			return { ...s, date: { from: value[0], to: value[1] } };
		}

		if (Array.isArray(value) && value.length === 0) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { [name]: y, ...restFilters }: Record<string, unknown> = filters;

			return restFilters;
		}

		return { ...s, [name]: value };
	});

	const pushFiltersToUrl = (pathname, filters) => history.push({
		pathname,
		search: encodeSearchParams(filters),
	});

	const clearQueryParams = () => {
		const queryParams = decodeSearchParams(query);
		if (Object.values(filters).length === 0) {
			pushFiltersToUrl(pathname, '');
		} else {
			Object.keys(filters).forEach((key) => {
				if (key in queryParams) {
					delete queryParams[key];
				}
			});
			setFilters({});
			pushFiltersToUrl(pathname, queryParams);
		}
	};

	const onDeselect = (fieldName: string, type: string, id?: number) => {
		let updatedFilters;
		if (type === 'id' && id) {
			updatedFilters = filters[fieldName]?.filter((elm) => elm !== id);
			setFilters((s) => ({ ...s, [fieldName]: updatedFilters }));
		} else if (type === 'text') {
			updatedFilters = { ...filters };
			delete updatedFilters[fieldName];
			setFilters(updatedFilters);
		}
	};

	const setQueryParams = () => {
		const sideQueryParams = getSideFilters(decodeSearchParams(query));
		const updatedQueryParams = { ...sideQueryParams, ...filters };
		pushFiltersToUrl(pathname, updatedQueryParams);
	};

	return [filters, onChange, setQueryParams, queryParams, clearQueryParams, onDeselect];
};
