import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Dropdown from 'antd/lib/dropdown';
import {
	CloseOutlined,
	DownloadOutlined,
	EditOutlined,
	MoreOutlined,
} from '@ant-design/icons';
import Button from 'antd/lib/button';

import { MobileCell } from '@common/react/components/Pages/ItemsPage';
import { WithId } from '@common/typescript/objects/WithId';
import { Pagination } from '@common/react/smart components/Table/TableDataProvider';

import { handleDelete } from '@app/components/UI/Table/services';
import { IActions } from '@app/components/Utils/ActionFactory';
import { request } from '@app/components/Api';
import { alertMessage, MessageType } from '@app/utilities/alert';
import { ResourceButton } from '@app/components/UI/Buttons/ResourceButton';

interface OwnProps {
	itemName?: string;
	actions: Array<IActions>;
}

interface DeleteRemoteProps {
	itemName: string;
	deleteEndpoint: string|undefined;
	requestParams: Record<string, unknown>|undefined;
	reload: () => void;
	onCompleted?: () => void;
	pagination?: Pagination;
	id: number;
	errorSettings?: (error: string) => void;
}

interface RequestButtonProps {
	itemName: string;
	updateEndpoint: string;
	requestParams?: Record<string, unknown>;
	reload: () => void;
	disabled?: boolean;
	message?: string;
	icon?: React.ReactNode,
}

interface EditButtonProps {
	onClick?: () => void;
	linkPath?: string;
}

interface DeleteWithReduxProps<T> {
	deleteItem: (e: React.MouseEvent<HTMLElement, MouseEvent>, item: T) => void;
	record: T;
}

interface PrintButtonProps {
	id: number;
}

const localKey = 'table.actions';

export const DeleteWithRedux = <T extends WithId>(props: DeleteWithReduxProps<T>): JSX.Element => {
	const { t } = useTranslation('translation', { keyPrefix: localKey });
	const { deleteItem, record } = props;

	return (
		<Button
			danger
			size="small"
			type="text"
			icon={<CloseOutlined />}
			title={t('delete')}
			onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => deleteItem(e, record)}
		>
			{t('delete')}
		</Button>
	);
};

export const DeleteRemote: React.FC<DeleteRemoteProps> = ({
	itemName, deleteEndpoint, requestParams,
	reload, id, pagination, errorSettings, onCompleted,
}) => {
	const { t } = useTranslation('translation');

	return (
		<Button
			danger
			type="text"
			size="small"
			icon={<CloseOutlined />}
			title={t(`${localKey}.delete`)}
			onClick={() => handleDelete({
				t,
				itemName,
				deleteEndpoint: deleteEndpoint ?? '',
				requestParams,
				reload,
				id,
				pagination,
				errorSettings,
				onCompleted,
			})}
		>
			{t(`${localKey}.delete`)}
		</Button>
	);
};

export const RequestButton: React.FC<RequestButtonProps> = ({
	itemName, updateEndpoint, requestParams, reload, disabled, message, icon,
}) => {
	const [loading, setLoading] = React.useState<boolean>(false);

	return (
		<Button
			title={itemName}
			loading={loading}
			size="small"
			type="text"
			disabled={disabled}
			onClick={() => {
				setLoading(true);
				request(updateEndpoint, requestParams)
					.then(() => {
						reload();
						alertMessage(MessageType.success, `Success! ${message}`);
					})
					.catch((error) => {
						alertMessage(MessageType.error, error);
					})
					.finally(() => setLoading(false));
			}}
			icon={icon}
		>
			{itemName}
		</Button>
	);
};

export const EditButton: React.FC<EditButtonProps> = ({ onClick, linkPath }) => {
	const { t } = useTranslation('translation', { keyPrefix: localKey });
	const history = useHistory();

	return (
		<Button
			type="text"
			size="small"
			title={t('edit')}
			icon={<EditOutlined />}
			onClick={() => (linkPath ? history.push(linkPath) : onClick?.())}
		>
			{t('edit')}
		</Button>
	);
};

export const PrintButton: React.FC<PrintButtonProps> = ({ id }: PrintButtonProps) => {
	const { t } = useTranslation('translation', { keyPrefix: localKey });

	return (
		<ResourceButton
			request={`/get-pet-tag?petId=${id}`}
			icon={<DownloadOutlined />}
			type="text"
			size="small"
		>
			{t('print')}
		</ResourceButton>
	);
};

export const ActionButtons: React.FC<OwnProps> = (props: OwnProps) => {
	const { t } = useTranslation('translation', { keyPrefix: 'table.columns' });
	const {
		actions,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		itemName,
	} = props;

	return (
		<MobileCell caption={t('actions')} fullWidth>
			<div className="user-actions-button">
				<Dropdown
					trigger={['click']}
					menu={{ items: actions }}
				>
					<Button
						type="text"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
						icon={<MoreOutlined />}
					/>
				</Dropdown>
			</div>
		</MobileCell>
	);
};
