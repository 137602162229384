import * as React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd/lib/grid';
import Skeleton from 'antd/lib/skeleton';

import { ImageComponent } from '@common/react/components/UI/Image/Image';

import { Crematory, CrematoryListInfo } from '@app/objects/Crematory';
import {
	isPickDefault,
	isEqualCrematory,
} from '@app/store/SelectList/SelectsInterfaces';
import { alertMessage, MessageType } from '@app/utilities/alert';
import { request } from '@app/components/Api';
import { CrematorySelect } from '@app/components/UI/Inputs/CrematorySelect';
import { ApplicationState } from '@app/store';
import { bindLoginActionCreators } from '@app/store/Login';
import { getActionCreators } from '@app/store/SelectList/ListActions';
import { noop } from '@app/services/debug';

interface SingleCrematoryProps {
	crematory: CrematoryListInfo;
}

const colProps = {
	xs: 24,
	sm: 24,
	className: 'user-crematory-select user-crematory-select--single',
};

const SingleCrematory: React.FC<SingleCrematoryProps> = (props: SingleCrematoryProps) => (
	<Col {...colProps}>
		<ImageComponent src={props.crematory.avatar} alt={props.crematory.name} className="crematory-logo__img" />
		<span className="icon-margin">{`${props.crematory.name}`}</span>
	</Col>
);

const CrematorySelectSkeleton: React.FC = () => (
	<Col {...colProps}>
		<Skeleton avatar={{ shape: 'circle' }} paragraph={false} active />
	</Col>
);

const actions = getActionCreators('crematories', { endpoint: 'crematoryList', isEqual: isEqualCrematory });

export const InfoHeader = () => {
	const dispatch = useDispatch();
	const factory = bindLoginActionCreators(dispatch);
	const reqSelect = React.useMemo(() => bindActionCreators(actions, dispatch), [dispatch]);
	const crematories = useSelector((state: ApplicationState) => state.selects.crematories);
	const activeCrematoryId = useSelector((state: ApplicationState) => state.login.user?.activeCrematoryId);
	const prev = React.useRef<number | undefined>(undefined);

	React.useEffect(() => {
		if (activeCrematoryId !== prev.current) {
			reqSelect.refresh(null, { preselect: activeCrematoryId ? [activeCrematoryId] : undefined });
		}
	}, [activeCrematoryId]);

	if (!crematories.items.length) return <CrematorySelectSkeleton />;
	if (crematories.items.length === 1 && activeCrematoryId) return <SingleCrematory crematory={crematories.items[0]} />;

	return (
		<Row className="user-crematory-select">
			<Col xs={24} sm={24}>
				<CrematorySelect
					value={activeCrematoryId ?? undefined}
					onChange={noop}
					onSelect={(value?: number) => {
						request<Crematory>('selectCrematory', { crematoryId: value })
							.then(() => {
								prev.current = value;
								factory.updateUser({ activeCrematoryId: value });

								// clean redux
								bindActionCreators(getActionCreators('crematories', { endpoint: '' }), dispatch).resetStore();
							})
							.catch((error: string) => alertMessage(MessageType.error, `Error. ${error}`));
					}}
					placeholder="Select Crematory"
					pickDefault={(store: Array<Crematory>) => isPickDefault(store)}
					allowClear={false}
					withLogo
				/>
			</Col>
		</Row>
	);
};
