import { v4 } from 'uuid';

import { Nullable, Optional, isPresent } from '@common/typescript/objects/Nullable';

import { PetEngraving, PetPrice } from '@app/objects/Pet';
import {
	Price,
	PriceKind,
	DiscountType,
	PriceType,
} from '@app/objects/Price';
import { KeyPriceParamsContainer, ProductContainer } from '@app/components/Utils/Prices/Helpers';
import { PriceFilter } from '@app/services/pricing/filters/PriceFilter';
import { DiscountService } from '@app/services/pricing/DiscountService';
import { IPriceStack } from '@app/services/pricing/IPriceStack';
import { ClinicDiscount, ApplyFilter } from '@app/objects/Clinic';
import { TaxPolicy } from '@app/objects/Crematory';

function sum(list: Array<PetPrice>): number {
	return list.reduce((acc: number, item: PetPrice) => acc + item.value, 0);
}

/**
 * This PriceStack is constructed based on Raw Data
 * This is used for creating new pets or editing existing ones (when prices are overridden)
 */
export class CalculatedPriceStack implements IPriceStack {
	private readonly _base: Nullable<PetPrice> = null;
	private readonly _engravings: Array<PetPrice> = [];
	private readonly _rush: Nullable<PetPrice> = null;
	private readonly _services: Array<PetPrice> = [];
	private readonly _urns: Array<PetPrice> = [];
	private readonly _products: Array<PetPrice> = [];
	private readonly _clinicDiscounts: Array<PetPrice> = [];
	private readonly _discount: Nullable<PetPrice> = null;
	private readonly _delivery: Nullable<PetPrice> = null;
	private readonly _pickup: Nullable<PetPrice> = null;

	private readonly _serviceTaxPercentage: number = 0;
	private readonly _productTaxPercentage: number = 0;

	private readonly _isTaxPolicyExcluded: boolean = true;

	private getBase(prices: Array<Price>, params: KeyPriceParamsContainer): Nullable<PetPrice> {
		const price = new PriceFilter(params).getBase(prices);
		if (!price) return null;

		return {
			id: -1,
			clientId: v4(),
			name: 'Base Price',

			value: price.value,
			extra: price.extra * Math.max((params.weight - price.to), 0),
			discount: 0,
			tax: 0,

			count: 0,
			completedCount: 0,
			done: false,

			batchPrice: price.batchPrice,
			batchCount: price.batchCount,

			price,
			priceId: price.id,

			pet: null,
			petId: params.id,

			editor: null,
			editorId: null,

			pickupService: null,
			pickupServiceId: null,

			node: null,
			nodeId: null,

			note: '',
		};
	}

	private getEngraving(prices: Array<Price>, params: KeyPriceParamsContainer): Array<PetPrice> {
		const source = prices
			.filter((item: Price) => item.priceKind === PriceKind.Engraving)
			.sort((a: Price, b: Price) => (a.order ?? 0) - (b.order ?? 0));
		const texts = params.engraving
			.filter(isPresent)
			.sort((a: PetEngraving, b: PetEngraving) => a.order - b.order);
		const result: Array<PetPrice> = [];

		const map = new Map<number, Price>();
		source.filter((item: Price) => item.clinicId === null)
			.forEach((item: Price) => {
				if (typeof (item.order) !== 'number') return;

				map.set(item.order, item);
			});
		source.filter((item: Price) => item.clinicId !== null)
			.forEach((item: Price) => {
				if (typeof item.order !== 'number') return;

				map.set(item.order, item);
			});

		const list = [...map.values()].sort((a: Price, b: Price) => (a.order ?? 0) - (b.order ?? 0));
		const count = Math.min(list.length, texts.length);

		for (let i = 0; i < count; i++) {
			const reference = list[i];
			result.push({
				id: -1,
				clientId: v4(),
				name: 'Engraving',

				value: reference.value,
				extra: 0,
				discount: 0,
				tax: 0,

				count: 0,
				completedCount: 0,
				done: false,

				batchPrice: 0,
				batchCount: 0,

				price: reference,
				priceId: reference.id,

				pet: null,
				petId: params.id,

				editor: null,
				editorId: null,

				pickupService: null,
				pickupServiceId: null,

				node: null,
				nodeId: null,

				note: '',
			});
		}

		return result;
	}

	private getRush(prices: Array<Price>, params: KeyPriceParamsContainer): Nullable<PetPrice> {
		if (!params.rush) return null;

		const price = new PriceFilter(params).getRush(prices);
		if (!price) return price;

		return {
			id: -1,
			clientId: v4(),
			name: 'Rush',

			value: price.value,
			extra: 0,
			discount: 0,
			tax: 0,

			count: 0,
			completedCount: 0,
			done: false,

			batchPrice: 0,
			batchCount: 0,

			price,
			priceId: price.id,

			pet: null,
			petId: params.id,

			editor: null,
			editorId: null,

			pickupService: null,
			pickupServiceId: null,

			node: null,
			nodeId: null,

			note: '',
		};
	}

	// check this method
	private getProducts(prices: Array<Price>, params: KeyPriceParamsContainer, type: PriceKind): Array<PetPrice> {
		const list = new PriceFilter(params).getProduct(prices, type);
		const key = type === PriceKind.UrnPrice ? 'urns' : 'products';

		return list.map<Nullable<PetPrice>>((item: Price) => {
			if (item.priceKind === type && item.priceKind === PriceKind.ProductPrice && item.inventoryItemId === null && !params.urns.length) {
				return {
					id: -1,
					clientId: v4(),
					name: 'No Item',

					value: item.value,
					extra: 0,
					discount: 0,
					tax: 0,

					count: 1,
					completedCount: 0,
					done: false,

					batchPrice: 0,
					batchCount: 0,

					price: item,
					priceId: item.id,

					pet: null,
					petId: params.id,

					storeEntryPick: null,

					editor: null,
					editorId: null,

					pickupService: null,
					pickupServiceId: null,

					node: null,
					nodeId: null,

					note: '',
				};
			}

			if (item.priceKind === type && item.priceKind === PriceKind.UrnPrice && item.inventoryItemId === null && !params.urns.length) {
				params.urns.forEach((u) => {
					return {
						id: -1,
						clientId: v4(),
						name: 'No Item',

						value: u.value,
						extra: 0,
						discount: 0,
						tax: 0,

						count: 1,
						completedCount: 0,
						done: false,

						batchPrice: 0,
						batchCount: 0,

						price: item,
						priceId: item.id,

						pet: null,
						petId: params.id,

						editor: null,
						editorId: null,

						pickupService: null,
						pickupServiceId: null,

						node: null,
						nodeId: null,

						note: '',
					};
				});
			}

			const source = params[key].find((q: ProductContainer) => q.categoryId === item.inventoryItemId);
			if (!source) return null;

			const extra = Math.max(source.count - item.batchCount, 0) * item.value;

			return {
				id: -1,
				clientId: v4(),
				name: item.inventoryItem?.name ?? 'Unknown item',

				value: source.value,
				extra,
				discount: 0,
				tax: 0,

				count: source.count,
				completedCount: 0,
				done: false,

				batchPrice: item.batchPrice,
				batchCount: item.batchCount,

				price: item,
				priceId: item.id,

				pet: null,
				petId: params.id,

				editor: null,
				editorId: null,

				pickupService: null,
				pickupServiceId: null,

				node: null,
				nodeId: null,

				note: '',
			};
		})
			.filter((item: Nullable<PetPrice>) => item !== null) as Array<PetPrice>;
	}

	private getDiscount(prices: Array<Price>, params: KeyPriceParamsContainer): Nullable<PetPrice> {
		const price = new PriceFilter(params).getDiscount(prices);
		if (!price) return null;

		return {
			id: -1,
			clientId: v4(),
			name: price.name,

			value: 0,
			extra: 0,
			discount: 0,
			tax: 0,

			count: 0,
			completedCount: 0,
			done: false,

			batchPrice: 0,
			batchCount: 0,

			price,
			priceId: price.id,

			pet: null,
			petId: params.id,

			editor: null,
			editorId: null,

			pickupService: null,
			pickupServiceId: null,

			node: null,
			nodeId: null,

			note: '',
		};
	}

	private getDelivery(prices: Array<Price>, params: KeyPriceParamsContainer): Nullable<PetPrice> {
		const price = new PriceFilter(params).getDelivery(prices);
		if (!price) return price;

		return {
			id: -1,
			clientId: v4(),
			name: 'Delivery',

			value: price.value,
			extra: 0,
			discount: 0,
			tax: 0,

			count: 0,
			completedCount: 0,
			done: false,

			batchPrice: 0,
			batchCount: 0,

			price,
			priceId: price.id,

			pet: null,
			petId: params.id,

			editor: null,
			editorId: null,

			pickupService: null,
			pickupServiceId: null,

			node: null,
			nodeId: null,

			note: '',
		};
	}

	private getClinicDiscount(discounts: Array<ClinicDiscount>, params: KeyPriceParamsContainer): Array<PetPrice> {
		const result: Array<PetPrice> = [];

		if (!discounts) return result;

		const list = discounts
			.filter((item: ClinicDiscount) => item.serviceType === params.serviceType)
			.filter((item: ClinicDiscount) => item.clinicId === params.clinicId)
			.filter((item: ClinicDiscount) => item.crematoryId === params.crematoryId);
		const cremation = list.find((item: ClinicDiscount) => item.appliesTo === ApplyFilter.BasePrice);
		const service = list.find((item: ClinicDiscount) => item.appliesTo === ApplyFilter.ServicePrice);

		if (cremation) {
			const discount = cremation.value / 100;
			const total = (this.base?.value ?? 0) + (this.base?.extra ?? 0);

			result.push({
				id: 0,
				name: cremation.name,
				clientId: v4(),

				petId: params.id,
				pet: null,

				priceId: 0,
				price: {
					id: 0,
					name: cremation.name,
					description: cremation.description,
					unit: DiscountType.Percentage,
					applyTo: PriceKind.BasePrice,

					petSpecieId: null,
					petSpecie: null,

					crematoryId: cremation.crematoryId,
					crematory: null,

					clinicId: cremation.clinicId,
					clinic: null,

					specialServiceId: null,
					specialService: null,

					pickupServiceId: null,

					inventoryItem: null,
					inventoryItemId: null,

					priceType: PriceType.Wholesale,
					serviceType: cremation.serviceType,
					priceKind: PriceKind.ClinicDiscount,
					deliveryType: null,

					from: 0,
					to: 0,

					value: cremation.value,
					extra: 0,

					batchPrice: 0,
					batchCount: 0,
					isDefault: false,
				},

				count: 0,
				done: false,
				completedCount: 0,

				value: total * discount,
				extra: 0,
				discount: 0,
				tax: 0,

				editor: null,
				editorId: null,

				pickupService: null,
				pickupServiceId: null,

				batchCount: 0,
				batchPrice: 0,

				entry: null,
				node: null,
				nodeId: null,
				toSelect: false,

				note: '',
			});
		}

		if (service) {
			const discount = service.value / 100;
			const total = this._services.reduce((acc: number, cur: PetPrice) => acc + cur.value, 0);
			result.push({
				id: 0,
				name: service.name,
				clientId: v4(),

				petId: params.id,
				pet: null,

				priceId: 0,
				price: {
					id: 0,
					name: service.name,
					description: service.description,
					unit: DiscountType.Percentage,
					applyTo: PriceKind.SpecialServicePrice,

					petSpecieId: null,
					petSpecie: null,

					crematoryId: service.crematoryId,
					crematory: null,

					clinicId: service.clinicId,
					clinic: null,

					specialServiceId: null,
					specialService: null,

					pickupServiceId: null,

					inventoryItem: null,
					inventoryItemId: null,

					priceType: PriceType.Wholesale,
					serviceType: service.serviceType,
					priceKind: PriceKind.ClinicDiscount,
					deliveryType: null,

					from: 0,
					to: 0,

					value: service.value,
					extra: 0,

					batchPrice: 0,
					batchCount: 0,
					isDefault: false,
				},

				count: 0,
				done: false,
				completedCount: 0,

				value: total * discount,
				extra: 0,
				discount: 0,
				tax: 0,

				editor: null,
				editorId: null,

				pickupService: null,
				pickupServiceId: null,

				batchCount: 0,
				batchPrice: 0,

				entry: null,
				node: null,
				nodeId: null,
				toSelect: false,

				note: '',
			});
		}

		return result;
	}

	private applyDiscount(fraction: number, prices: Array<PetPrice>): number {
		let total: number = 0;

		prices.forEach((item: PetPrice) => {
			let value = item.value + (item.price?.priceKind === PriceKind.BasePrice ? item.price.extra : 0);
			value = Math.max(value - item.discount);

			const discount = value * fraction;
			item.discount += discount;
			total += discount;
		});

		return total;
	}

	private applyAbsoluteDiscount(discount: number, prices: Array<PetPrice>): number {
		let budget = discount;
		let total = 0;

		prices.forEach((item: PetPrice) => {
			if (budget <= 0) return;

			let value = item.value + (item.price?.priceKind === PriceKind.BasePrice ? item.extra : 0);
			value = Math.max(value - item.discount, 0);

			const result = Math.max(value - budget, 0);
			const diff = value - result;

			item.discount += diff;
			budget -= diff;
			total += diff;
		});

		return total;
	}

	private applyClinicDiscounts(): void {
		const discounts = this._clinicDiscounts;
		if (!discounts.length) return;

		discounts.forEach((item: PetPrice) => {
			if (!item.price) return;

			if (item.price.applyTo === PriceKind.BasePrice && this._base) {
				item.value = this.applyDiscount(item.price.value / 100, [this._base]);
			}

			if (item.price.applyTo === PriceKind.SpecialServicePrice && this.services.length) {
				item.value = this.applyDiscount(item.price.value / 100, this.services);
			}
		});
	}

	private applyDiscounts(): void {
		this.applyClinicDiscounts();

		const discount = this._discount;
		if (!discount) return;
		if (!discount.price) return;

		const apply: (prices: Array<Optional<PetPrice>>) => number = (prices: Array<Optional<PetPrice>>): number => {
			if (!discount.price) return 0;

			const items: Array<PetPrice> = prices.filter(isPresent);
			if (!prices.length) return 0;

			if (discount.price.unit === DiscountType.Percentage) {
				return this.applyDiscount(discount.price.value / 100, items);
			}

			if (discount.price.unit === DiscountType.Value) {
				return this.applyAbsoluteDiscount(discount.price.value, items);
			}

			return 0;
		};

		switch (discount.price.applyTo) {
		case PriceKind.BasePrice:
			// eslint-disable-next-line no-case-declarations
			discount.value = apply([this._base]);

			return;

		case PriceKind.SpecialServicePrice:
			discount.value = apply(this._services);

			return;

		case PriceKind.UrnPrice:
			// eslint-disable-next-line no-case-declarations
			discount.value = apply(this._urns);

			return;

		default:
			// eslint-disable-next-line no-case-declarations
			const list: Array<PetPrice> = [
				this._base,
				...this._services,
				...this._urns,
			].filter(isPresent);
			if (!list.length) return;

			discount.value = apply(list);
		}
	}

	private calculateIncludedTax(fraction: number, value: number): number {
		return (value * fraction) / (fraction + 1);
	}

	private calculateExcludedTax(fraction: number, value: number): number {
		return value * fraction;
	}

	private applyTax(items: Array<PetPrice>, fraction: number, policy: TaxPolicy): number {
		const calculate = policy === TaxPolicy.TaxIncluded ? this.calculateIncludedTax : this.calculateExcludedTax;
		let total: number = 0;

		items.forEach((item: PetPrice) => {
			let value = item.value + (item.price?.priceKind === PriceKind.BasePrice ? item.extra : 0);
			value = Math.max(value - item.discount, 0);

			const tax = calculate(fraction, value);
			item.tax = tax;
			total += tax;
		});

		return total;
	}

	private applyTaxes([serviceFraction, productFraction]: [number, number], policy: TaxPolicy): [number, number] {
		const services: Array<PetPrice> = [
			this.base,
			...this.engravings,
			this.rush,
			this.delivery,
			this.pickup,
		].filter(isPresent);
		const products: Array<PetPrice> = [
			...this.urns,
			...this.products,
		];

		return [this.applyTax(services, serviceFraction, policy), this.applyTax(products, productFraction, policy)];
	}

	private clear(item: Optional<PetPrice>): void {
		if (!item) return;

		item.tax = 0;
		item.discount = 0;
	}

	private clearList(list: Array<PetPrice>): void {
		if (!list.length) return;

		list.forEach((item: PetPrice) => {
			item.tax = 0;
			item.discount = 0;
		});
	}

	private clearAll(): void {
		this.clear(this._base);
		this.clear(this._rush);
		this.clear(this._discount);
		this.clear(this._pickup);
		this.clear(this._delivery);

		this.clearList(this._services);
		this.clearList(this._urns);
		this.clearList(this._products);
		this.clearList(this._engravings);
	}

	public constructor(
		prices: Array<Price>,
		services: Array<PetPrice>,
		products: Array<PetPrice>,
		params: KeyPriceParamsContainer,
		clinicDiscounts: Array<ClinicDiscount>,
		taxPolicy: TaxPolicy,
	) {
		this._base = this.getBase(prices, params);
		this._engravings = this.getEngraving(prices, params);
		this._rush = this.getRush(prices, params);
		this._services = services
			.filter((item: PetPrice) => !item.removed)
			.filter((item: PetPrice) => item.price?.priceKind === PriceKind.SpecialServicePrice);
		this._urns = params.urns.filter((item: PetPrice) => !item.removed);
		this._products = products
			.filter((item: PetPrice) => !item.removed)
			.filter((item: PetPrice) => item.price?.priceKind === PriceKind.ProductPrice);

		this._clinicDiscounts = clinicDiscounts.length
			? this.getClinicDiscount(clinicDiscounts, params)
			: services.filter((item: PetPrice) => item?.price?.priceKind === PriceKind.ClinicDiscount && !item.removed);
		this._discount = this.getDiscount(prices, params);

		this._delivery = this.getDelivery(prices, params);
		this._pickup = services
			.filter((item: PetPrice) => !item.removed)
			.find((item: PetPrice) => item.price?.priceKind === PriceKind.PickupPrice) ?? null;

		this._serviceTaxPercentage = params.summary.serviceTaxPercentage / 100;
		this._productTaxPercentage = params.summary.productTaxPercentage / 100;

		this.clearAll();
		this.applyDiscounts();
		this.applyTaxes([this._serviceTaxPercentage, this._productTaxPercentage], taxPolicy);

		this._isTaxPolicyExcluded = taxPolicy === TaxPolicy.TaxExcluded;
	}

	public get base(): Nullable<PetPrice> {
		return this._base;
	}

	public get engravings(): Array<PetPrice> {
		return this._engravings;
	}

	public get services(): Array<PetPrice> {
		return this._services;
	}

	public get products(): Array<PetPrice> {
		return this._products;
	}

	public get urns(): Array<PetPrice> {
		return this._urns;
	}

	public get productTotal(): number {
		const products = sum(this.products);
		const urns = sum(this.urns);

		return products + urns;
	}

	public get productTaxPercentage(): number {
		return this._productTaxPercentage;
	}

	public get productTaxTotal(): number {
		const productTax = this._isTaxPolicyExcluded ? this.productTaxPercentage : this.productTaxPercentage * 100;
		const tax = productTax * (this.productTotal - (this.discount?.value ?? 0));
		const result = this._isTaxPolicyExcluded ? tax : tax / (100 + productTax);

		return Math.max(result, 0);
	}

	public get serviceTotal(): number {
		const base = (this.base?.value ?? 0) + (this.base?.extra ?? 0);
		const services = sum(this.services) + sum(this.engravings) + (this.rush?.value ?? 0);

		return base + services - sum(this.clinicDiscounts);
	}

	public get serviceTaxPercentage(): number {
		return this._serviceTaxPercentage;
	}

	public get serviceTaxTotal(): number {
		const serviceTax = this._isTaxPolicyExcluded ? this.serviceTaxPercentage : this.serviceTaxPercentage * 100;
		const tax = serviceTax * (this.serviceTotal - (this.discount?.value ?? 0) + (this.delivery?.value ?? 0));
		const result = this._isTaxPolicyExcluded ? tax : tax / (100 + serviceTax);

		return Math.max(result, 0);
	}

	public get discount(): Nullable<PetPrice> {
		return this._discount;
	}

	public get pickup(): Nullable<PetPrice> {
		return this._pickup;
	}

	public get clinicDiscounts(): Array<PetPrice> {
		return this._clinicDiscounts;
	}

	public get delivery(): Nullable<PetPrice> {
		return this._delivery;
	}

	public get rush(): Nullable<PetPrice> {
		return this._rush;
	}

	public get subtotal(): number {
		return this.serviceTotal + this.productTotal - (this.discount?.value ?? 0) + (this.delivery?.value ?? 0);
	}

	public get taxTotal(): number {
		return this.serviceTaxTotal + this.productTaxTotal;
	}

	public get total(): number {
		return this._isTaxPolicyExcluded ? this.subtotal + this.taxTotal : this.subtotal;
	}
}
