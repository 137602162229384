import { TFunction } from 'react-i18next';

import { List } from '@common/typescript/objects/List';
import { WithId } from '@common/typescript/objects/WithId';
import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { Nullable } from '@common/typescript/objects/Nullable';

import { User } from '@app/objects/User';
import { DeliveryType, ServiceType, WeightSource } from '@app/objects/Pet';
import { ChemicalsTable } from '@app/objects/ChemicalsTable';
import { Carrier } from '@app/objects/Carriers';
import { Language } from '@app/objects/Language';
import { Country } from '@app/objects/Country';
import { Clinic } from '@app/objects/Clinic';
import { toLowerCase } from '@app/components/Utils/Utils';
import { State } from '@app/objects/State';
import { useCrematory } from '@app/hooks/useCrematory';
import { InvoiceTaxModel } from '@app/objects/Invoice';

export enum StatusFlowPolicy
{
	DisallowSkipping = 0,
	AllowSkipping = 1,
}

export enum WeightUnits {
	Pounds = 0,
	Kilograms = 1,
}

export enum CrematoryType {
	Cremation = 0,
	Aquamation = 1,
	Both = 2,
}

export enum TaxPolicy {
	TaxExcluded = 0,
	TaxIncluded = 1,
}

export interface Units {
	weight: WeightUnits;
}

export function toString(policy: StatusFlowPolicy) {
	switch (policy) {
	case StatusFlowPolicy.DisallowSkipping:
		return 'Disallow Skipping';

	case StatusFlowPolicy.AllowSkipping:
		return 'Allow Skipping';

	default:
		return 'Unknown Policy';
	}
}

export interface CrematoryServiceTypePreferences extends WithId {
	useServiceType: boolean; // Включен ли у данного крематория указанный ServiceType
	showInternalId: boolean;
	requireInternalId: boolean;
	retailRequiresUrn: boolean;
	wholesaleRequiresUrn: boolean;
	retailStatusFlowPolicy: StatusFlowPolicy;
	wholesaleStatusFlowPolicy: StatusFlowPolicy;
	petColor: string;
	specialPetColor: string;
	defaultRetailDeliveryType: DeliveryType;
	defaultWholesaleDeliveryType: DeliveryType;
}

export interface Crematory extends WithDeleted {
	name: string;
	externalToken: string;
	requestToken: string;

	contactFirstName: string;
	contactLastName: string;

	streetAddress: string;
	streetAddress2: string;
	city: string;
	state: Nullable<State>;
	stateId: number;
	zip: string;

	email: string;
	phone: string;
	webUrl: string;

	users?: List<User>;

	avatar: string;
	originalAvatar: string;

	tagIcon: string;
	originalTagIcon: string;

	inventoryNotificationTime: number;
	defaultCarrier?: Carrier;

	defaultInventoryItemId?: number;

	clinicInbox: string;
	receiveClinicMails: boolean;

	language: Nullable<Language>;
	languageId: number;

	country: Nullable<Country>;
	countryId: number;

	dateFormat: string;
	timeFormat: string;

	importClinicId: Nullable<number>;
	importClinic: Nullable<Clinic>;

	retailServiceTax: number,
	retailProductTax: number,
	wholesaleServiceTax: number,
	wholesaleProductTax: number,
	taxId: Nullable<string>;
	wholesaleTaxPolicy: TaxPolicy;
	retailTaxPolicy: TaxPolicy;

	weightSource: WeightSource;
	alwaysRaiseModal: boolean;

	billingId: Nullable<number>;

	prefillEngraving: boolean;
	clinicCanUploadFiles: boolean;

	units: Units;
	showPricesToClinic: boolean;

	private: CrematoryServiceTypePreferences;
	semiPrivate: CrematoryServiceTypePreferences;
	communal: CrematoryServiceTypePreferences;
	storage: CrematoryServiceTypePreferences;
	productsOnly: CrematoryServiceTypePreferences;

	crematoryType: CrematoryType;
	manageStock: boolean;

	invoiceTaxModel: InvoiceTaxModel;
}

export interface CrematoryMachine extends WithId {
	name: string;
	description: string;

	crematory: Crematory | null;
	crematoryId: number;

	chemicalsTable: ChemicalsTable | null;
	chemicalsTableId: number | null;
}

export interface CrematorySelectInfo extends WithId {
	name: string;
}

export interface CrematoryListInfo extends WithId {
	name: string;

	avatar: string;
	originalAvatar: string;

	email: string;
	phone: string;

	contactFirstName: string;
	contactLastName: string;

	streetAddress: string;
	streetAddress2: string;
	city: string;
	state: string;
	zip: string;

	defaultCarrier?: Carrier;
	defaultCarrierId?: number;

	language: string;
	dateFormat: string;
	timeFormat: string;

	country: string;

	weightSource: WeightSource,

	retailServiceTax: number,
	retailProductTax: number,
	wholesaleServiceTax: number,
	wholesaleProductTax: number,
	wholesaleTaxPolicy: TaxPolicy;
	retailTaxPolicy: TaxPolicy;
	invoiceTaxModel: InvoiceTaxModel;

	prefillEngraving: boolean;
	showPricesToClinic: boolean;
	clinicCanUploadFiles: boolean;

	units: Units;

	private: CrematoryServiceTypePreferences;
	semiPrivate: CrematoryServiceTypePreferences;
	communal: CrematoryServiceTypePreferences;
	storage: CrematoryServiceTypePreferences;
	productsOnly: CrematoryServiceTypePreferences;

	manageStock: boolean;
}

export interface CrematoryPreferences extends WithId {
	communalColor: string;
	specialCommunalColor: string;

	semiPrivateColor: string;
	specialSemiPrivateColor: string;

	privateColor: string;
	specialPrivateColor: string;

	inventoryNotificationTime: number;

	clinicInbox: string;
	receiveClinicMails: boolean;

	language: Nullable<Language>;
	languageId: number;

	country: Nullable<Country>;
	countryId: number;

	dateFormat: string;
	timeFormat: string;
}

export interface ExpirationColoring extends WithId {
	crematory?: Crematory | null;
	crematoryId: number;

	days: number;
	color: string;
}

export interface CustomerServiceView extends WithId {
	name: string;
	email: string;
	phone: string;
	webUrl: string;
	avatar: string;
}

type ColorPreferences = Pick<Crematory, 'communal' | 'semiPrivate' | 'private' | 'storage'>;

export function barColor(serviceType: ServiceType, isSpecial: boolean = false, crematory?: Nullable<ColorPreferences>): string {
	if (!crematory) return 'transparent';

	switch (serviceType) {
	case ServiceType.Communal:
		return isSpecial ? crematory.communal.specialPetColor : crematory.communal.petColor;

	case ServiceType.SemiPrivate:
		return isSpecial ? crematory.semiPrivate.specialPetColor : crematory.semiPrivate.petColor;

	case ServiceType.Private:
		return isSpecial ? crematory.private.specialPetColor : crematory.private.petColor;

	case ServiceType.Storage:
		return isSpecial ? crematory.storage.specialPetColor : crematory.storage.petColor;

	default:
		return '';
	}
}

export const getDefaultCrematoryServiceTypePreferences = (): CrematoryServiceTypePreferences => ({
	id: -1,
	useServiceType: false,
	showInternalId: false,
	requireInternalId: false,
	retailRequiresUrn: false,
	wholesaleRequiresUrn: false,
	retailStatusFlowPolicy: StatusFlowPolicy.DisallowSkipping,
	wholesaleStatusFlowPolicy: StatusFlowPolicy.DisallowSkipping,
	petColor: '',
	specialPetColor: '',
	defaultRetailDeliveryType: DeliveryType.None,
	defaultWholesaleDeliveryType: DeliveryType.None,
});

export function getDefaultCrematory(): Crematory {
	return {
		id: -1,

		externalToken: '',
		requestToken: '',

		name: '',
		avatar: '',
		originalAvatar: '',

		contactFirstName: '',
		contactLastName: '',
		email: '',
		phone: '',
		webUrl: '',

		streetAddress: '',
		streetAddress2: '',
		city: '',
		state: null,
		stateId: -1,
		zip: '',

		tagIcon: '',
		originalTagIcon: '',

		inventoryNotificationTime: 0,

		clinicInbox: '',
		receiveClinicMails: false,

		languageId: -1,
		language: null,

		countryId: -1,
		country: null,

		dateFormat: 'MM/dd/YYYY',
		timeFormat: 'hh:mm a',

		importClinicId: null,
		importClinic: null,

		retailServiceTax: 0,
		retailProductTax: 0,
		wholesaleServiceTax: 0,
		wholesaleProductTax: 0,
		taxId: null,
		wholesaleTaxPolicy: TaxPolicy.TaxExcluded,
		retailTaxPolicy: TaxPolicy.TaxExcluded,

		weightSource: WeightSource.ReportedWeight,
		alwaysRaiseModal: false,

		billingId: null,

		prefillEngraving: false,
		clinicCanUploadFiles: false,

		units: {
			weight: WeightUnits.Pounds,
		},
		showPricesToClinic: true,

		private: getDefaultCrematoryServiceTypePreferences(),
		semiPrivate: getDefaultCrematoryServiceTypePreferences(),
		communal: getDefaultCrematoryServiceTypePreferences(),
		storage: getDefaultCrematoryServiceTypePreferences(),
		productsOnly: getDefaultCrematoryServiceTypePreferences(),

		crematoryType: CrematoryType.Cremation,
		manageStock: true,

		invoiceTaxModel: InvoiceTaxModel.TaxPerItem,
	};
}

export function reduceName(unit?: WeightUnits) {
	if (unit === WeightUnits.Kilograms) return 'kg';

	return 'lbs';
}

export function getWeightUnitName(weight: number, weightUnit?: WeightUnits) {
	if (weightUnit === undefined) return weight;

	return `${weight} ${reduceName(weightUnit)}`;
}

export function getUnitName(weightUnit: WeightUnits, t: TFunction) {
	switch (weightUnit) {
	case WeightUnits.Pounds:
		return t('enums.weight-units.pounds');
	case WeightUnits.Kilograms:
		return t('enums.weight-units.kilograms');

	default:
		return '-';
	}
}

export enum UseServiceType {
	useCommunal = 'useCommunal',
	usePrivate = 'usePrivate',
	useSemiPrivate = 'useSemiPrivate',
	useStorage = 'useStorage',
	useProductsOnly = 'useProductsOnly',
}

const defaultUsesCrematoryServiceType = {
	[UseServiceType.useCommunal]: false,
	[UseServiceType.usePrivate]: false,
	[UseServiceType.useSemiPrivate]: false,
	[UseServiceType.useStorage]: false,
	[UseServiceType.useProductsOnly]: false,
};

export function useCrematoryServiceType(): Record<UseServiceType, boolean> {
	const crematory = useCrematory();

	if (!crematory) return defaultUsesCrematoryServiceType;

	return {
		[UseServiceType.useCommunal]: crematory?.communal.useServiceType,
		[UseServiceType.usePrivate]: crematory?.private.useServiceType,
		[UseServiceType.useSemiPrivate]: crematory?.semiPrivate.useServiceType,
		[UseServiceType.useStorage]: crematory?.storage.useServiceType,
		[UseServiceType.useProductsOnly]: crematory?.productsOnly.useServiceType,
	};
}

type CrematoryServiceTypePreferencesKind = 'all' | 'communal' | 'private' | 'semiPrivate' | 'storage' | 'productsOnly'

export function getServiceTypePreferenceKind(type?: ServiceType): CrematoryServiceTypePreferencesKind {
	return toLowerCase(ServiceType[type ?? 0]) as CrematoryServiceTypePreferencesKind;
}
