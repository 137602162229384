import React from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'antd/lib/select';
import { Col, Row } from 'antd/lib/grid';
import { useFormikContext } from 'formik';

import { List } from '@common/typescript/objects/List';
import { isPresent, Nullable } from '@common/typescript/objects/Nullable';

import { useRequest } from '@app/hooks/useRequest';
import { DeliveryType, DeliveryTypeName, ServiceType } from '@app/objects/Pet';
import { PriceType } from '@app/objects/Price';
import { useDebounce } from '@app/hooks/useDebounce';
import { FormikField } from '@app/components/Forms/FormikField';
import { FormValues } from '@app/components/Various/PriceEditor/PriceEditor';

interface OwnProps {
	serviceType: ServiceType;
	priceType: PriceType;
	clinicId: Nullable<number>;

	isModalOpen: boolean;
}

const deliveryTypeKey = 'delivery-type';

interface RequestParams {
	count: number;
	offset: number;
	filters: {
		priceType: PriceType,
		serviceType: ServiceType,
		availableForClinic: Nullable<number>,
	},
	preselect: Array<number>;
	name: string;
}

export const DeliveryPriceComponent: React.FC<OwnProps> = ({
	serviceType,
	priceType,
	clinicId,

	isModalOpen,
}: OwnProps): JSX.Element => {
	const { t } = useTranslation();
	const { values } = useFormikContext<FormValues>();
	const translator = React.useCallback((key: string) => t(`filters.${key}`), []);
	const [query, setQuery] = React.useState<string>('');

	const params = React.useMemo(() => ({
		count: 20,
		offset: 0,
		filters: {
			priceType,
			serviceType,
			availableForClinic: clinicId,
		},
		preselect: isPresent(values.deliveryType) ? [values.deliveryType] : [],
		name: query,
	}), [priceType, serviceType, clinicId, values.deliveryType]);

	const data = useRequest<List<DeliveryType>, RequestParams>('availableDeliveryTypeList', undefined, { requestOnMount: false });

	React.useEffect(() => {
		if (!isModalOpen) return;

		data.reload(params);
	}, [isModalOpen, params]);

	const debounce = useDebounce((value: Nullable<string>) => data.reload({ ...params, name: value ?? '' }), 750);

	return (
		<Row gutter={[30, 16]}>
			<Col xs={24} sm={24} md={12}>
				<FormikField
					fieldName="value"
					title="Price*"
					inputProps={{
						type: 'number',
						min: 0,
						inputMode: 'decimal',
					}}
				/>
			</Col>
			<Col xs={24} sm={24} md={12}>
				<FormikField
					fieldName="deliveryType"
					title="Delivery Type*"
					render={({ field, form }) => (
						<Select
							showSearch
							showArrow
							showAction={['focus', 'click']}
							optionFilterProp="children"
							placeholder={translator(deliveryTypeKey)}
							value={field.value === null ? undefined : field.value}
							onChange={(value) => form.setFieldValue(field.name, value)}
							onSearch={(query: string) => debounce(query)}
							onSelect={() => setQuery('')}
							onBlur={() => setQuery('')}
						>
							{
								data.item?.list.map((value) => (
									<Select.Option value={value} key={value}>
										{t(`enums.${deliveryTypeKey}.${DeliveryTypeName[DeliveryType[value]]}`)}
									</Select.Option>
								))
							}
						</Select>
					)}
				/>
			</Col>
		</Row>
	);
};
