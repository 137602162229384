import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import Select, { SelectProps } from 'antd/lib/select';
import clsx from 'clsx';

import {
	isWithName,
} from '@common/typescript/objects/WithName';
import { Nullable } from '@common/typescript/objects/Nullable';
import { getLoadableScrollHandler } from '@common/react/components/Utils/Utils';
import { GetKeysByType } from '@common/typescript/utils/types';
import { WithId } from '@common/typescript/objects/WithId';

import { ApplicationState } from '@app/store';
import {
	getActionCreators,
	IActionCreators,
	SelectRequestParams,
	ActionCreatorProps,
} from '@app/store/SelectList/ListActions';
import {
	SelectList,
	GeneralKey,
	getSelectItemState,
} from '@app/store/SelectList/SelectList';
import { useDebounce } from '@app/hooks/useDebounce';
import {
	SelectRequestProps,
	isEmptyValue,
} from '@app/store/SelectList/SelectsInterfaces';
import {
	EntityType,
	FilterType,
	StoreType,
	ItemsType,
	RecordType,
} from '@app/store/SelectList/UtilityTypes';
import { selectListRequest } from '@app/store/SelectList/SelectListRequests';

/* Type Definitions */

type NameGetter<T> = GetKeysByType<T, string> | ((entity: T) => React.ReactNode);
type DefaultPicker<TValue, TStore extends keyof SelectList> = (entity: ItemsType<TStore>) => TValue;

export interface GroupProps<T extends WithId> {
	title: string;
	options: Array<T>;
}

interface BaseProps<TValue, TStore extends keyof SelectList, TParams extends SelectRequestParams<TValue>> {
	store: TStore;
	storeKey?: string;
	filters: Nullable<FilterType<TStore>>;
	reqParams?: Partial<TParams>;

	// eslint-disable-next-line react/no-unused-prop-types
	className?: string;
	selectClassName?: string;
	activeClassName?: string;
	style?: React.CSSProperties;

	// eslint-disable-next-line react/no-unused-prop-types
	label?: React.ReactElement | string;
	placeholder?: string;
	pickDefault?: DefaultPicker<TValue, TStore>;
	shouldPickDefault: (store, multiple, isEmptyValue, reqProps, value) => boolean;
	disabled?: boolean;

	onSelect?: (value: TValue) => void;
	onDeselect?: (value: TValue) => void;

	onFocus?: () => void;
	onBlur?: () => void;
	onSearch?: (query: string) => void;
	filterOption?: (inputValue, option) => boolean;

	getName?: NameGetter<EntityType<TStore>>;
	getDisabledOption?: (item: EntityType<TStore>) => boolean;
	group?: (items: Array<EntityType<TStore>>) => Array<GroupProps<EntityType<TStore>>>;
	order?: ((items: Array<EntityType<TStore>>) => Array<EntityType<TStore>>) | boolean;
	shouldFetch?: boolean;

	allowClear?: boolean;
	noPreselect?: boolean;

	localOptions?: Array<EntityType<TStore>>;
}

interface SingleValueProps<
	TValue,
	TStore extends keyof SelectList,
	TParams extends SelectRequestParams<TValue>
> extends BaseProps<TValue, TStore, TParams> {
	value?: TValue;
	onChange: (value: TValue | undefined) => void;

	// eslint-disable-next-line react/no-unused-prop-types
	multiple?: false;
	isEmptyValue?: (value: TValue | undefined) => boolean;
}

interface MultipleValueProps<
	TValue,
	TStore extends keyof SelectList,
	TParams extends SelectRequestParams<TValue>
> extends BaseProps<TValue, TStore, TParams> {
	value?: Array<TValue>;
	onChange: (value: Array<TValue> | undefined) => void;

	// eslint-disable-next-line react/no-unused-prop-types
	multiple: true;
	isEmptyValue?: (value: Array<TValue> | undefined) => boolean;
}

export type CoreSelectProps<
	TValue,
	TStore extends keyof SelectList,
	TParams extends SelectRequestParams<TValue>
> = SingleValueProps<TValue, TStore, TParams> | MultipleValueProps<TValue, TStore, TParams>;

interface LoadMoreProps {
	fetch: () => void;
	shouldFetch: boolean;
}

interface ActiveProps {
	isActive: boolean;
	isEmpty: boolean;
	setFocus: (focus: boolean) => void;
}

const { Option, OptGroup } = Select;

/* Utility functions */

function getLabel(label?: React.ReactElement | string): React.ReactNode {
	if (!label) return null;
	if (typeof label === 'string') return <span className="field-name">{label}</span>;

	return label;
}

function defaultGetter<T>(item: T): string {
	if (isWithName(item) && item.name) return item.name;

	return '-';
}

function getName<TStore extends keyof SelectList>(
	item: EntityType<TStore>,
	getter: NameGetter<EntityType<TStore>> = defaultGetter,
): React.ReactNode {
	if (typeof getter === 'function') return getter(item);
	const record: Record<GetKeysByType<EntityType<TStore>, string>, string> = item as Record<GetKeysByType<EntityType<TStore>, string>, string>;

	return record[getter];
}

function defaultOrder<TStore extends keyof SelectList>(items: Array<EntityType<TStore>>): Array<EntityType<TStore>> {
	return items;
}

/* Helper hooks */
// Preselect logic (value preload value that's been selected)
function usePreselect<
	TValue,
	TStore extends keyof SelectList,
	TParams extends SelectRequestParams<TValue>,
	>(props: CoreSelectProps<TValue, TStore, TParams>): void {
	const reqProps: SelectRequestProps<TStore> = selectListRequest[props.store];
	const store: StoreType<TStore> = useSelector<ApplicationState, StoreType<TStore>>((state: ApplicationState) =>
		state.selects[props.store]);

	const dispatch = useDispatch();
	const factory: IActionCreators<TValue, TStore> = React.useMemo(
		() => {
			const creatorProps: ActionCreatorProps<TStore> = {
				endpoint: reqProps.endpoint,
				key: props.storeKey ?? GeneralKey,
				isEqual: reqProps.isEqual,
			};

			return bindActionCreators(getActionCreators<TValue, TStore>(props.store, creatorProps), dispatch);
		},
		[dispatch, props.storeKey],
	);

	React.useEffect(() => {
		let value = props.value;
		if (!reqProps.isEmpty(store.filters as Nullable<FilterType<TStore>>) || props.noPreselect) {
			value = undefined;
		}

		if (reqProps.isValid && !reqProps.isValid(props.filters)) return;
		if (props.shouldFetch === false) return;

		const preselect: Array<TValue> = Array.isArray(value) ? value : [];
		if (!Array.isArray(value) && value !== undefined && value !== null) {
			preselect.push(value);
		}

		factory.request(props.filters, { ...props.reqParams, preselect });
	}, [factory, props.filters, props.reqParams, props.shouldFetch]);
}

// Pick default value logic
function useDefault<
	TValue,
	TStore extends keyof SelectList,
	TParams extends SelectRequestParams<TValue>,
>(props: CoreSelectProps<TValue, TStore, TParams>): void {
	const reqProps: SelectRequestProps<TStore> = selectListRequest[props.store];
	const root: StoreType<TStore> = useSelector<ApplicationState, StoreType<TStore>>((state: ApplicationState) =>
		state.selects[props.store]);
	const store: RecordType<TStore> = getSelectItemState<TStore>(root, props.storeKey);

	React.useEffect(() => {
		if (props.shouldPickDefault(store, props.multiple, props.isEmptyValue, reqProps, props.value) && props.pickDefault) {
			const value = props.pickDefault(store.items as ItemsType<TStore>);

			if (value !== undefined) {
				if (props.multiple) {
					props.onChange([value]);
				} else {
					props.onChange(value);
				}
			}
		}
	}, [store.items, store.filters, props.pickDefault, reqProps.isEmpty]);
}

function useLoadMore<
	TValue,
	TStore extends keyof SelectList,
	TParams extends SelectRequestParams<TValue>,
>(props: CoreSelectProps<TValue, TStore, TParams>, count: number = 20): LoadMoreProps {
	const reqProps: SelectRequestProps<TStore> = selectListRequest[props.store];
	const key = props.storeKey ?? GeneralKey;
	const root: StoreType<TStore> = useSelector<ApplicationState, StoreType<TStore>>((state: ApplicationState) =>
		state.selects[props.store]);
	const store = getSelectItemState(root, key);

	const dispatch = useDispatch();
	const factory = React.useMemo(
		() => {
			const creatorProps: ActionCreatorProps<TStore> = {
				endpoint: reqProps.endpoint,
				key,
				isEqual: reqProps.isEqual,
			};

			return bindActionCreators(getActionCreators<TValue, TStore>(props.store, creatorProps), dispatch);
		},
		[dispatch],
	);

	return {
		fetch: () => factory.loadMoreItems(count),
		shouldFetch: store.items.length < store.pagination.total,
	};
}

function useActive<
	TValue,
	TStore extends keyof SelectList,
	TParams extends SelectRequestParams<TValue>
>(props: CoreSelectProps<TValue, TStore, TParams>): ActiveProps {
	const [focused, setFocus] = React.useState<boolean>(() => false);

	if (props.multiple) {
		const emptyCheck = props.isEmptyValue ?? isEmptyValue;
		const isEmpty = emptyCheck(props.value);
		const isActive = focused || !isEmpty;

		return {
			isEmpty,
			isActive,
			setFocus,
		};
	}

	const emptyCheck = props.isEmptyValue ?? isEmptyValue;
	const isEmpty = emptyCheck(props.value);
	const isActive = focused || !isEmpty;

	return {
		isEmpty,
		isActive,
		setFocus,
	};
}

function useOptions<
	TValue,
	TStore extends keyof SelectList,
	TParams extends SelectRequestParams<TValue>
>(props: CoreSelectProps<TValue, TStore, TParams>): React.ReactNode {
	const { order = false } = props;
	const reqProps: SelectRequestProps<TStore> = selectListRequest[props.store];

	const key = props.storeKey ?? GeneralKey;
	const root: StoreType<TStore> = useSelector<ApplicationState, StoreType<TStore>>((state: ApplicationState) =>
		state.selects[props.store]);
	const store = getSelectItemState(root, key);

	let newOrder = order ? reqProps.order : defaultOrder;
	if (typeof order === 'function') newOrder = order;

	const localOptions = props.localOptions ?? [];
	const options: Array<EntityType<TStore>> = [...store.items, ...localOptions] as Array<EntityType<TStore>>;
	let nodes: Array<React.ReactElement> | undefined;

	if (props.group) {
		nodes = props.group(options).map((item: GroupProps<EntityType<TStore>>) => (
			<OptGroup label={item.title} key={item.title}>
				{
					newOrder?.(item.options)
						.map((item: EntityType<TStore>) => (
							<Option
								key={item.id}
								value={item.id}
							>
								{getName(item, props.getName)}
							</Option>
						))
				}
			</OptGroup>
		));
	} else {
		nodes = newOrder?.(options)
			.map((item: EntityType<TStore>) => (
				<Option
					key={item.id}
					value={item.id}
					disabled={props.getDisabledOption?.(item)}
				>
					{getName(item, props.getName)}
				</Option>
			));
	}

	return nodes;
}

function useSelect<
	TValue,
	TStore extends keyof SelectList,
	TParams extends SelectRequestParams<TValue>
>(props: CoreSelectProps<TValue, TStore, TParams>): SelectProps<TValue> | SelectProps<Array<TValue>> {
	const root: StoreType<TStore> = useSelector<ApplicationState, StoreType<TStore>>((state: ApplicationState) =>
		state.selects[props.store]);
	const store = getSelectItemState(root);

	const debounce = useDebounce<string>((value: Nullable<string>) => {
		const str = value ?? '';
		props.onSearch?.(str);
	}, 750);

	usePreselect(props);
	useDefault(props);

	const loadMore = useLoadMore(props);
	const active = useActive(props);
	const options = useOptions(props);

	const properties = {
		showSearch: true,
		showArrow: true,
		allowClear: props.allowClear ?? true,
		showAction: ['focus', 'click'],

		value: active.isEmpty ? undefined : props.value,
		onChange: props.onChange,

		onSearch: (query: string) => debounce(query),
		onSelect: (value) => {
			props.onSearch?.('');
			if (value === undefined) return;

			props.onSelect?.(value);
		},
		onDeselect: (value) => {
			if (value === undefined) return;

			props.onDeselect?.(value);
		},
		onPopupScroll: getLoadableScrollHandler(loadMore.fetch, loadMore.shouldFetch),
		onFocus: () => {
			active.setFocus(true);
			props.onFocus?.();
		},
		onBlur: () => {
			active.setFocus(false);

			props.onSearch?.('');
			props.onBlur?.();
		},

		optionFilterProp: 'children',
		filterOption: props.filterOption,
		mode: props.multiple ? 'multiple' : 'default',

		className: clsx(props.selectClassName, active.isActive && props.activeClassName),
		placeholder: props.placeholder,
		loading: store.isLoading,
		disabled: props.disabled,
		children: options,
	};

	if (props.multiple) return properties as SelectProps<Array<TValue>>;

	return properties as SelectProps<TValue>;
}

/* Facade component */
/**
 * CoreSelect is a redux-driven base select.
 * Any select that uses data from server should use this component as its basis.
 * @param {CoreSelectProps<TValue, TStore, TParams>} props
 * @returns {React.ReactElement}
 * @constructor
 */
export const CoreSelect = <
	TValue,
	TStore extends keyof SelectList,
	TParams extends SelectRequestParams<TValue> = SelectRequestParams<TValue>
>(props: CoreSelectProps<TValue, TStore, TParams>): React.ReactElement => {
	const properties = useSelect<TValue, TStore, TParams>(props);

	return (
		<div className={props.className} style={props.style}>
			{getLabel(props.label)}
			<span className="ant-select-selection__placeholder">{props.placeholder}</span>
			{
				props.multiple
					? <Select<Array<TValue>> {...(properties as SelectProps<Array<TValue>>)} />
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					: <Select<TValue> {...(properties as SelectProps<TValue>)} />
			}
		</div>
	);
};
