import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import {
	loadableDelay, loadable, getParams,
} from '@common/react/loadable/loadableSettings';
import PrivateRoute from '@common/react/components/Pages/PrivateRoute';

import { UserRole } from '@app/objects/User';
import { UserDependantRoute } from '@app/UserDependantRoute';
import { Layout } from '@app/components/Layout';
import { RoutePaths } from '@app/utilities/RoutePathVars';

import { CrematoryClinics } from '@app/components/Pages/Crematories/CrematoryClinics';
import { OldPetEditor } from '@app/components/Pages/PetEditor/OldPetEditor/Editor';

/* --------------Admin---------------*/

const Users = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "UsersPage" */ '@app/components/Pages/Users/Users')), getParams('Users'));
const UserEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "UserEditorPage" */ '@app/components/Pages/Users/UserEditor')), getParams('UserEditor'));
const AdminClinics = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AdminClinics" */ '@app/components/Pages/Clinics/AdminClinics')), getParams('AdminClinics'));
const Crematories = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CrematoriesPage" */ '@app/components/Pages/Crematories/Crematories')), getParams('Crematories'));
const ClinicEditor = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "ClinicEditorPage" */ '@app/components/Pages/Clinics/ClinicEditor/ClinicEditor')),
	getParams('ClinicEditor'),
);
const ChemicalsTableEditor = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "ChemicalsTableBuilderPage" */
			'@app/components/Pages/Crematories/CrematoryEditorTabs/ChemicalsTables/CrematoryChemicalsTableEditor'
		)),
	getParams('CrematoryChemicalsTableEditor'),
);
const PurchaseOrderEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PurchaseOrderEditor" */
		'@app/components/Pages/Inventory/PurchaseOrder/PurchaseOrderEditor'
	)), getParams('PurchaseOrderEditor'));

/* ------------Admin end-------------*/
const NotFound = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "NotFoundPage" */ '@app/components/Pages/Support/NotFound')), getParams('NotFound'));
const ClinicPetEditor = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "ClinicPetEditor" */ '@app/components/Pages/ClinicPetEditor/ClinicPetEditor')),
	getParams('ClinicPetEditor'),
);
const Login = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "LoginPage" */ '@app/components/Pages/Login/Login')), getParams('Login'));
const Recover = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RecoverPage" */ '@app/components/Pages/Recover/Recover')), getParams('Recover'));
const Confirm = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ConfirmPage" */ '@app/components/Pages/Confirm/Confirm')), getParams('Confirm'));
const Register = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RegisterPage" */ '@app/components/Pages/Register/Register')), getParams('Register'));
const Profile = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ProfilePage" */ '@app/components/Pages/Profile/Profile')), getParams('Profile'));
const Dashboard = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DashboardPage" */ '@app/components/Pages/Dashboard/Dashboard')), getParams('Dashboard'));
const Admin = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DashboardPage" */ '@app/components/Pages/Admin/Admin')), getParams('Admin'));
const CrematoryAdmin = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "DashboardPage" */ '@app/components/Pages/Crematories/CrematoryAdmin')),
	getParams('CrematoryAdmin'),
);
const Inventory = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "DashboardPage" */ '@app/components/Pages/Crematories/CrematoryInventory')),
	getParams('Inventory'),
);
export const Pets = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PetsPage" */ '@app/components/Pages/Pets/Pets')), getParams('Pets'));
const CrematoryPetList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PetsPage" */ '@app/components/Pages/Pets/CrematoryPetList')), getParams('CrematoryPetList'));
const ClinicPetList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClinicPetsPage" */ '@app/components/Pages/Pets/ClinicPetsList')), getParams('ClinicPetList'));
const PetEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PetEditor" */ '@app/components/Pages/PetEditor/PetEditor')), getParams('PetEditor'));
const InvoiceEditor = loadable(
	() =>
		loadableDelay(
			import(/* webpackChunkName: "InvoiceEditor" */ '@app/components/Pages/Clinics/ClinicTabs/Invoices/InvoiceEditor/InvoiceEditor'),
		),
	getParams('InvoiceEditor'),
);
const RouteEditor = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "InvoiceEditor" */ '@app/components/Pages/Clinics/ClinicTabs/Routes/RouteEditor/RouteEditor')),
	getParams('RouteEditor'),
);
const NLStatelessPage = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "NLStatelessPage" */
			'@app/components/Pages/NotLoggedPages/NLStatelessPage'
		)),
	getParams('NLStatelessPage'),
);
const CrematoryUsers = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "CrematoryUsersPage" */
			'@app/components/Pages/Users/CrematoryUsers'
		)),
	getParams('CrematoryUsersPage'),
);
const ClinicUsers = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "ClinicUsersPage" */
			'@app/components/Pages/Users/ClinicUsers'
		)),
	getParams('ClinicUsersPage'),
);
const CrematoryUserDashboard = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CrematoryUserDashboard" */
		'@app/components/Pages/CrematoryUserDashboard/CrematoryUserDashboard'
	)), getParams('CrematoryUserDashboard'));
const PetListStatusDetails = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "PetListStatusDetails" */
			'@app/components/Pages/CrematoryUserDashboard/PetListStatusDetails'
		)),
	getParams('PetListStatusDetails'),
);
const PetListSpecialServiceDetails = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PetListSpecialServiceDetails" */
		'@app/components/Pages/CrematoryUserDashboard/PetListSpecialServiceDetails'
	)), getParams('PetListSpecialServiceDetails'));
const PickupReportsPage = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "LocationReportsPage" */ '@app/components/Pages/Dashboard/PickupReport/PickupReport')),
	getParams('PickupReport'),
);

const CrematoryBatchEditor = loadable(
	() =>
		loadableDelay(import(
		/* webpackChunkName: "CrematoryBatchEditor" */ '@app/components/Pages/Pets/Batch/BatchEditor/CrematoryBatchEditor'
		)),
	getParams('CrematoryBatchEditor'),
);

const ClinicDashboard = loadable(
	() =>
		loadableDelay(import('@app/components/Pages/Dashboard/ClinicDashboard')),
	getParams('ClinicDashboard'),
);

const Reports = loadable(
	() => loadableDelay(import('@app/components/Pages/Reports/ReportsList')),
	getParams('Reports'),
);
const AverageTurnAroundReport = loadable(
	() => loadableDelay(import('@app/components/Pages/Reports/AverageTurnAroundReport/AverageTurnAroundReport')),
	getParams('AverageTurnAroundReport'),
);
const AverageTurnAroundDetails = loadable(
	() => loadableDelay(import('@app/components/Pages/Reports/AverageTurnAroundReport/AverageTurnAroundDetails')),
	getParams('AverageTurnAroundDetails'),
);
const IncomeReportList = loadable(
	() => loadableDelay(import('@app/components/Pages/Reports/IncomeReportList')),
	getParams('IncomeReportList'),
);
const UrnReport = loadable(
	() => loadableDelay(import('@app/components/Pages/Reports/UrnReport/UrnReport')),
	getParams('UrnReport'),
);
const ClinicInvoiceReport = loadable(
	() => loadableDelay(import('@app/components/Pages/Reports/ClinicInvoiceReport')),
	getParams('ClinicInvoiceReport'),
);
const LabelPaperEditor = loadable(
	() => loadableDelay(import('@app/components/Pages/Admin/LabelPaper/LabelPaperEditor/LabelPaperEditor')),
	getParams('LabelPaperEditor'),
);

const CrematoryDependentQuickbooks = loadable(
	() => loadableDelay(import('@app/CrematoryDependentFeatures/services')),
	getParams('CrematoryDependentQuickbooks'),
);

const ManageApiKeysPage = loadable(
	() => loadableDelay(import('@app/components/Pages/Users/ManageApiKeys/ApiKeys')),
	getParams('ApiKeys'),
);

const CountriesEditor = loadable(
	() => loadableDelay(import('@app/components/Pages/Admin/Countries/CountriesEditor/CountriesEditor')),
	getParams('CountriesEditor'),
);

const QuickbooksItemEditor = loadable(
	() => loadableDelay(import('@app/components/Pages/Quickbooks/QuickbooksTabs/Items/QuickbooksItemEditor/QuickbooksItemEditor')),
	getParams('QuickbooksItemEditor'),
);
const StoreEntryEditor = loadable(
	() => loadableDelay(import('@app/components/Pages/Inventory/StoreEntries/StoreEntryEditor/StoreEntryEditor')),
	getParams('StoreEntryEditor'),
);
const ImportDetails = loadable(
	() => loadableDelay(import('@app/components/Pages/Pets/Imports/ImportDetails')),
	getParams('ImportDetails'),
);

const UserDependentManageUserPermission = loadable(
	() => loadableDelay(import('@app/UserDependentPermissions/services')),
	getParams('UserDependentManageUserPermission'),
);

const ComplaintEditor = loadable(
	() => loadableDelay(import('@app/components/Pages/Admin/Complaints/ComplaintEditor/ComplaintEditor')),
	getParams('ComplaintEditor'),
);

const ClinicPreferences = loadable(
	() => loadableDelay(import('@app/components/Pages/Clinics/ClinicManagerPreferences')),
	getParams('ClinicPreferences'),
);

const petEditorComponents = {
	[UserRole.Admin]: OldPetEditor,
	[UserRole.Crematory]: OldPetEditor,
	[UserRole.ClinicUser]: ClinicPetEditor,
	[UserRole.ClinicManager]: ClinicPetEditor,
	[UserRole.CrematoryUser]: PetEditor,
};

const adminComponents = {
	[UserRole.Admin]: Admin,
	[UserRole.Crematory]: CrematoryAdmin,
	[UserRole.ClinicUser]: Login,
	[UserRole.ClinicManager]: Login,
	[UserRole.CrematoryUser]: Login,
};

const dashboardComponents = {
	[UserRole.Admin]: Dashboard,
	[UserRole.Crematory]: Dashboard,
	[UserRole.ClinicUser]: ClinicDashboard,
	[UserRole.ClinicManager]: ClinicDashboard,
	[UserRole.CrematoryUser]: CrematoryUserDashboard,
};

const PetList = {
	[UserRole.Admin]: Pets,
	[UserRole.Crematory]: CrematoryPetList,
	[UserRole.ClinicUser]: ClinicPetList,
	[UserRole.ClinicManager]: ClinicPetList,
	[UserRole.CrematoryUser]: Pets,
};

const usersList = {
	[UserRole.Admin]: Users,
	[UserRole.Crematory]: Login,
	[UserRole.ClinicUser]: Login,
	[UserRole.ClinicManager]: ClinicUsers,
	[UserRole.CrematoryUser]: Login,
};

const userEditor = {
	[UserRole.Admin]: UserDependentManageUserPermission,
	[UserRole.Crematory]: UserDependentManageUserPermission,
	[UserRole.ClinicUser]: Login,
	[UserRole.ClinicManager]: UserDependentManageUserPermission,
	[UserRole.CrematoryUser]: UserDependentManageUserPermission,
};

const PetComponents = {
	[UserRole.Admin]: Pets,
	[UserRole.Crematory]: CrematoryPetList,
	[UserRole.ClinicUser]: ClinicPetList,
	[UserRole.ClinicManager]: ClinicPetList,
	[UserRole.CrematoryUser]: Pets,
};

const ClinicComponents = {
	[UserRole.Admin]: AdminClinics,
	[UserRole.Crematory]: CrematoryClinics,
	[UserRole.ClinicUser]: Login,
	[UserRole.ClinicManager]: Login,
	[UserRole.CrematoryUser]: Login,
};

const reportsList = {
	[UserRole.Admin]: Login,
	[UserRole.Crematory]: Reports,
	[UserRole.ClinicUser]: Login,
	[UserRole.ClinicManager]: Login,
	[UserRole.CrematoryUser]: Login,
};

const QuickbookComponents = {
	[UserRole.Admin]: Login,
	[UserRole.Crematory]: CrematoryDependentQuickbooks,
	[UserRole.ClinicUser]: Login,
	[UserRole.ClinicManager]: Login,
	[UserRole.CrematoryUser]: Login,
};

export const routes = (
	<Layout>
		<Switch>
			<Route exact path="/" component={Login} />
			<Route
				path={RoutePaths.crematories}
				render={() =>
					(<NLStatelessPage key="crematories-not-logged-page" title="Crematories" path="crematories" />)}
			/>
			<Route path={RoutePaths.login} component={Login} />
			<Route path={RoutePaths.recover} component={Recover} />
			<Route path={RoutePaths.confirm} component={Confirm} />
			<Route path={RoutePaths.registration} component={Register} />
			<PrivateRoute path={RoutePaths.profile} component={Profile} />
			<PrivateRoute path={RoutePaths.labelPaperEditor} component={LabelPaperEditor} />
			<PrivateRoute path={RoutePaths.countriesEditor} component={CountriesEditor} />
			<PrivateRoute path={RoutePaths.apiImportDetails} component={ImportDetails} />
			<PrivateRoute path={RoutePaths.batchEditor} component={CrematoryBatchEditor} />
			<PrivateRoute path={RoutePaths.complaintEditor} component={ComplaintEditor} />

			<UserDependantRoute
				path={RoutePaths.dashboard}
				dependantComponents={dashboardComponents}
			/>
			<UserDependantRoute
				path={RoutePaths.admin}
				dependantComponents={adminComponents}
			/>

			<UserDependantRoute
				path={RoutePaths.petEditor}
				dependantComponents={petEditorComponents}
			/>
			<UserDependantRoute
				exact
				path={RoutePaths.pets}
				dependantComponents={PetComponents}
			/>
			<UserDependantRoute
				path={RoutePaths.petList}
				dependantComponents={PetList}
			/>
			<UserDependantRoute
				path={RoutePaths.userList}
				dependantComponents={usersList}
			/>
			<PrivateRoute path={RoutePaths.apiKeys} component={ManageApiKeysPage} />
			<UserDependantRoute
				path={RoutePaths.userEditor}
				dependantComponents={userEditor}
			/>

			{/* /reports */}
			<PrivateRoute path={RoutePaths.averageTurnAroundDetails} component={AverageTurnAroundDetails} />
			<PrivateRoute path={RoutePaths.averageTurnAround} component={AverageTurnAroundReport} />
			<PrivateRoute path={RoutePaths.incomeReport} component={IncomeReportList} />
			<PrivateRoute path={RoutePaths.urnReport} component={UrnReport} />
			<PrivateRoute path={RoutePaths.clinicInvoiceReport} component={ClinicInvoiceReport} />
			<UserDependantRoute
				path={RoutePaths.reportsList}
				dependantComponents={reportsList}
			/>

			{/* /quickbooks */}
			<PrivateRoute path={RoutePaths.quickbooksItemEditor} component={QuickbooksItemEditor} />
			<UserDependantRoute
				path={RoutePaths.quickbooks}
				dependantComponents={QuickbookComponents}
			/>

			{/* /clinics/ */}
			<PrivateRoute path={RoutePaths.clinicEditor} component={ClinicEditor} />
			<PrivateRoute path={RoutePaths.invoiceEditor} component={InvoiceEditor} />
			<PrivateRoute path={RoutePaths.routeEditor} component={RouteEditor} />
			<UserDependantRoute
				path={RoutePaths.clinics}
				dependantComponents={ClinicComponents}
			/>

			<PrivateRoute path={RoutePaths.crematoryList} component={Crematories} />
			<PrivateRoute path={RoutePaths.storeEntryEditor} component={StoreEntryEditor} />
			<PrivateRoute path={RoutePaths.purchaseOrderEditor} component={PurchaseOrderEditor} exact />
			<PrivateRoute path={RoutePaths.inventory} component={Inventory} />

			<PrivateRoute path={RoutePaths.invoiceEditor} component={InvoiceEditor} />
			<PrivateRoute path={RoutePaths.purchaseOrderEditor} component={PurchaseOrderEditor} />
			<PrivateRoute path={RoutePaths.crematoryUsers} component={CrematoryUsers} />
			<PrivateRoute path={RoutePaths.crematoryUserEditor} component={UserEditor} />
			<PrivateRoute path={RoutePaths.petListStatusDetails} component={PetListStatusDetails} />
			<PrivateRoute path={RoutePaths.petListSpecialServiceDetails} component={PetListSpecialServiceDetails} />
			<PrivateRoute path={RoutePaths.pickupReports} component={PickupReportsPage} />
			<PrivateRoute path={RoutePaths.chemicalsTableBuilder} component={ChemicalsTableEditor} />
			<PrivateRoute path={RoutePaths.clinicPreferences} component={ClinicPreferences} />

			<Route path="*" component={NotFound} />
		</Switch>
	</Layout>
);
