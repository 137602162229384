import React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { v4 } from 'uuid';

import { List } from '@common/typescript/objects/List';
import { isPresent, Nullable } from '@common/typescript/objects/Nullable';

import { ApplicationState } from '@app/store';
import { request } from '@app/components/Api';
import { StoreEntryNode } from '@app/objects/StoreEntry';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { Price, PriceType } from '@app/objects/Price';
import { PetPrice, ServiceType } from '@app/objects/Pet';
import { getPriceValue } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/SpecialServiceList';
import { calculate } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/StoreEntriesModal/calculations';
import { allowAdd } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/StoreEntriesModal/StoreEntriesModal';

interface DefaultUrn {
	prices: Array<Price>;
	node: StoreEntryNode;
}

interface CurrentParams {
	serviceType: ServiceType;
	priceType: PriceType;
	crematoryId: number;
	clinicId: Nullable<number>;
}

export function getPetPrice(price: Price, item: StoreEntryNode, petId: number): PetPrice {
	const value = getPriceValue(price);

	const entry = item.entry;
	if (entry) {
		entry.tree = entry.tree.map((node: StoreEntryNode) => ({
			...node,
			entry: null,
			parent: null,
			children: [],
		}));
	}

	const node = entry?.tree.find((q: StoreEntryNode) => q.id === item.id) ?? null;
	const toSelect = !allowAdd(entry, item.id);

	return {
		id: -1,
		clientId: v4(),
		removed: false,

		priceId: price.id,
		price,

		pet: null,
		petId,

		count: 1,
		completedCount: 0,
		done: false,

		value: value.value,
		extra: value.extra,
		tax: 0,
		discount: 0,

		batchCount: price.batchCount,
		batchPrice: price.batchPrice,

		entry,
		node,
		nodeId: item.id,

		name: item.name,
		editor: null,
		editorId: null,

		pickupService: null,
		pickupServiceId: null,

		toSelect,

		note: '',
	};
}

function changedUrns(oldUrns: Array<PetPrice>, newUrns: Array<PetPrice>): Array<PetPrice> {
	const deletedUrns = oldUrns
		.filter((urn) => urn.id > 0)
		.map((item: PetPrice) => ({ ...item, removed: true }));

	return [...deletedUrns, ...newUrns];
}

/**
 * Print log warning in a standardized fashion
 * @param {string} message - warning message
 * @param {DefaultUrn} item - item that has issues
 */
function logWarning(message: string, item: DefaultUrn): void {
	console.warn(`${message}. See details below`);
	console.log('Default urn details: ', item);
}

// Неудачное имя. Это не хук
export function useDefaultUrnRequest(
	params: CurrentParams,
	onChange: (field: string, value: boolean | Array<PetPrice>) => void,
	currentUrns: Array<PetPrice>,
	petId: number,
) {
	onChange('loadingUrns', true);

	request<List<DefaultUrn>, CurrentParams>('defaultUrnList', params) // params
		.then((list: List<DefaultUrn>) => {
			const urns = list.list
				.map((item: DefaultUrn) => {
					if (!item.node.entry) {
						logWarning('Missing node entry for default urn', item);

						return null;
					}

					const entry = item.node.entry;
					entry.tree.push(item.node);
					entry.tree = entry.tree.map((node: StoreEntryNode) => ({
						...node,

						parent: null,
						children: [],
					}));

					const node = entry.tree.find((node: StoreEntryNode) => node.id === item.node.id);
					if (!node) {
						logWarning('Missing node in current entry\'s node tree', item);

						return null;
					}

					const data = calculate({
						entry,
						selection: node,
						prices: item.prices,
						count: 1,
					});
					if (!data) {
						logWarning('Unable to calculate BriefPrice for default urn', item);

						return null;
					}

					return getPetPrice(data.price, node, petId);
				}).filter(isPresent);

			const updateUrns = changedUrns(currentUrns, urns);
			onChange('urns', updateUrns);
		})
		.finally(() => onChange('loadingUrns', false));
}

function equals(a: CurrentParams, b: CurrentParams): boolean {
	return a.crematoryId === b.crematoryId
		&& a.clinicId === b.clinicId
		&& a.serviceType === b.serviceType
		&& a.priceType === b.priceType;
}

function toParams(values: Pick<PetFormValues, 'clinicId' | 'serviceType' | 'priceType'>, crematoryId: number): CurrentParams {
	return {
		crematoryId,
		clinicId: values.clinicId,
		serviceType: values.serviceType,
		priceType: values.priceType,
	};
}

export const DefaultUrnProvider: React.VFC = () => {
	const { values, initialValues, setFieldValue } = useFormikContext<PetFormValues>();

	const activeCrematoryId = useSelector((state: ApplicationState) => state.login.user?.activeCrematoryId);
	const crematoryId = !activeCrematoryId ? initialValues.crematoryId : activeCrematoryId;
	const previous = React.useRef<CurrentParams>(toParams(initialValues, crematoryId));

	React.useEffect(() => {
		if (crematoryId <= 0) return;

		const cur: CurrentParams = toParams(values, crematoryId);
		if (!equals(cur, previous.current) || values.id < 0) {
			previous.current = cur;
			useDefaultUrnRequest(cur, setFieldValue, values.urns, values.id);
		}
	}, [values.id, values.clinicId, values.serviceType, values.priceType, crematoryId]);

	return null;
};
