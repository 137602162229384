import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'antd/lib/tooltip';
import Button from 'antd/lib/button';
import { CopyOutlined, PlusOutlined } from '@ant-design/icons';

import { useModal } from '@app/hooks/Editors/useModal';
import { CopyModal } from '@app/components/Utils/CopyRequest';
import { List } from '@app/components/UI/List/List';

export interface ListHeaderProps {
	name?: React.ReactNode;
	showAddButton?: boolean;
	copyButton?: boolean;
	extraBtn?: React.ReactNode;
	extraContent?: React.ReactNode;
	itemName?: string;
	handleAddNewClick?: () => void;
	addButtonProps?: Record<string, unknown>;
	withHeaderMargin?: boolean;
	customAddButton?: JSX.Element;
	headerClassName?: string;

	reload?: () => void;
	reqParams?: Record<string, unknown>;
	copyEndpoint?: string;
	successMessage?: string;
	errorMessage?: string;
	title?: string;
	content?: (modalState: boolean) => React.ReactNode;
	buttonCopyName?: string;
	copyModalWidth?: string;
	okButtonProps?: Record<string, unknown>;
	backPath?: string;
}

type AddButtonProps = Pick<ListHeaderProps, 'customAddButton' | 'handleAddNewClick' | 'itemName' | 'addButtonProps'>
interface CopyButtonProps {
	buttonName?: string;
	onOpen: () => void;
}

const getPageTitle = (name: React.ReactNode) => {
	if (typeof name === 'string') return <h1 className="header">{name}</h1>;

	return name;
};

export const AddButton: React.FC<AddButtonProps> = ({
	itemName, customAddButton, handleAddNewClick, addButtonProps,
}: AddButtonProps) => {
	const { t } = useTranslation();
	const buttonAddName = `${t('buttons.add')} ${itemName}`;

	if (customAddButton) return customAddButton;

	return (
		<>
			<span className="btn--add-with-icon">
				<Tooltip title={buttonAddName}>
					<Button
						type="primary"
						icon={<PlusOutlined />}
						onClick={handleAddNewClick}
						{...addButtonProps}
					/>
					<span className="visually-hidden">{buttonAddName}</span>
				</Tooltip>
			</span>
			<span className="btn--add-with-word">
				<Button
					type="primary"
					onClick={handleAddNewClick}
					{...addButtonProps}
				>
					<span>{buttonAddName}</span>
				</Button>
			</span>
		</>
	);
};

export const CopyButton: React.FC<CopyButtonProps> = ({ buttonName, onOpen }: CopyButtonProps) => (
	<>
		<span className="btn--add-with-icon">
			<Tooltip title={buttonName}>
				<Button
					type="primary"
					ghost
					icon={<CopyOutlined />}
					onClick={onOpen}
					className="pull-right"
				/>
				<span className="visually-hidden">{buttonName}</span>
			</Tooltip>
		</span>
		<span className="btn--add-with-word">
			<Button
				type="primary"
				ghost
				className="pull-right"
				onClick={onOpen}
			>
				<span>{buttonName}</span>
			</Button>
		</span>
	</>
);

export const ListHeader: React.FC<ListHeaderProps> = ({
	name,
	showAddButton = true,
	copyButton,
	extraBtn,
	extraContent,
	itemName,
	handleAddNewClick,
	withHeaderMargin = false,
	reload,
	reqParams,
	copyEndpoint,
	successMessage,
	errorMessage,
	title,
	content,
	buttonCopyName,
	copyModalWidth,
	okButtonProps,
	backPath,
	customAddButton,
	addButtonProps,
	headerClassName,
}): JSX.Element => {
	const header = name ? getPageTitle(name) : null;
	const modal = useModal(false);

	return 	(
		<div style={withHeaderMargin ? { marginTop: '30px' } : undefined}>
			<List.Header className={headerClassName}>
				<List.Header.Title backPath={backPath}>
					{header}
				</List.Header.Title>
				<List.Header.Actions>
					{copyButton && content && (<CopyButton buttonName={buttonCopyName} onOpen={modal.open} />)}
					{extraBtn}
					{showAddButton && <AddButton itemName={itemName} handleAddNewClick={handleAddNewClick} customAddButton={customAddButton} addButtonProps={addButtonProps} />}
				</List.Header.Actions>
			</List.Header>
			{extraContent}
			{content && (
				<CopyModal
					modal={modal}
					modalWidth={copyModalWidth}
					copyEndpoint={copyEndpoint}
					errorMessage={errorMessage}
					successMessage={successMessage}
					title={title}
					content={() => content?.(modal.state)}
					reqParams={reqParams}
					reload={reload}
					okButtonProps={okButtonProps}
				/>
			)}
		</div>
	);
};
