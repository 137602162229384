import { ChangeRenderHandler, ValueChange } from '@app/components/Pages/PetEditor/OldPetEditorTabs/ChangeRender/ChangeRenderHandler';
import { KeyManager } from '@app/components/Pages/PetEditor/OldPetEditorTabs/ChangeRender/KeyManager';

interface FormatValues {
	from: number;
	to: number;
}

function format(from: string, to: string): FormatValues {
	return {
		from: Number.parseFloat(from),
		to: Number.parseFloat(to),
	};
}

export class NumberRenderHandler implements ChangeRenderHandler {
	public apply(value: ValueChange): boolean {
		const obj = format(value.original, value.updated);

		return !Number.isNaN(obj.from)
		&& !Number.isNaN(obj.to)
		&& !KeyManager.for(value.key).isEnumGroup()
		&& !KeyManager.for(value.key).isInternalIdNum();
	}

	render(value: ValueChange): ValueChange {
		const obj = format(value.original, value.updated);

		if (KeyManager.for(value.key).isIntegerGroup()) {
			return {
				key: value.key,
				original: obj.from.toFixed(0),
				updated: obj.to.toFixed(0),
			};
		}

		return {
			key: value.key,
			original: obj.from.toFixed(2),
			updated: obj.to.toFixed(2),
		};
	}
}
