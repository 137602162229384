/* eslint-disable radix */
import dayjs from 'dayjs';
import { parse, stringify } from 'query-string';
import * as Yup from 'yup';

import { List } from '@common/typescript/objects/List';
import { Named } from '@common/react/utils/utils';
import { Sorter } from '@common/react/smart components/Table/TableRemoteSorter';
import { SortingDirection } from '@common/react/components/Pages/ItemsPage';

import { ExpirationColoring } from '@app/objects/Crematory';
import { filterKeys, Filters } from '@app/components/UI/Filters/FilterHook';
/** An abstraction over different date types */
export type DateType = Date | dayjs.Dayjs | number | null | undefined;

export const dateFormat = 'MM/DD/yyyy';
export const timeFormat = 'hh:mm a';

export const phoneMask = (code: string) => [...code.split(''), ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const phoneFormat = /^\+\d{1,3} \(\d{3}\) \d{3}-\d{4}$/;

export const FILE_INFO_MESSAGE = 'Image size must not exceed 5 Mb';

export enum ViewMode {
	view = 'view',
	edit = 'edit',
}

export enum DeviceType {
	mobile = 0,
	desktop = 1,
}

export interface ResultDatesRange {
	from: number | undefined,
	to: number | undefined,
}

/**
 * Function takes formatted phone (i.e. +1 (999) 642-2120 and converts it to raw number string (+19996422120)
 * @param {string | null | undefined} phone - formatted phone string (it's not being verified)
 * @returns {string} Formatted phone
 */
export const phoneReplace = (phone: string | null | undefined): string => (phone?.replace(/[()\-\s]/g, '') ?? '');

/**
 * Function converts phone saved as number string to formatted phone string (i.e. +19996422120 to +1 (999) 642-2120)
 * @param {string | null | undefined} phone - raw number string phone
 * @returns {string} formatted phone string
 */
export const formatPhone = (phone: string | null | undefined): string => (phone?.replace(/^\+1(\d{3})(\d{3})(\d{4})$/, '+1 ($1) $2-$3') ?? '');

/**
 * Function checks if specified string is formatted phone string
 * @param {string | null | undefined} phone - formatted phone string
 * @returns {boolean} True if string is formatted phone string, false otherwise
 */
export const isFormattedPhone = (phone: string | null | undefined): boolean =>
	(phone ? new RegExp(/^\+1 \(\d{3}\) \d{3}-\d{4}$/, undefined).test(phone) : false);

/**
 * Converts string of type 'testString' to 'TestString' - making first char to be upper cased
 * @param {string} item - string for conversion
 * @return {string}
 */
export const toUpperCase = (item: string): string => item[0].toUpperCase() + item.slice(1);
export const toLowerCase = (item: string): string => item[0].toLowerCase() + item.slice(1);

export const toWords = (camelCase: string): string => camelCase.replace(/([a-z])([A-Z])/g, '$1 $2');
/**
 * Returns concatenated name string. Use this function in order to have uniform names
 * @param {Named} item - any object that has firstName and lastName
 * @return {string}
 */
export const getUserName = (item: Named): string => (item ? `${item.firstName || ''} ${item.lastName || ''}`.trim() : '');

/**
 * Options that control how date should be formatted
 */
interface FormatDateOptions {
	view: 'short' | 'long';
	withTime: boolean;
	withWeekDay: boolean;
	fallback: string;
}

/**
 * Convert DateType object to string to display
 * @param {DateType} date - date that needs to be displayed
 * @param {Partial<FormatDateOptions>} formatOptions - options that control formatting
 * @return {string}
 */
export const formatDate = (date: DateType, formatOptions?: Partial<FormatDateOptions>): string => {
	const locale = 'en-US';
	let options: Intl.DateTimeFormatOptions;
	const formatDateOptions: FormatDateOptions = {
		view: formatOptions?.view ?? 'short',
		withTime: formatOptions?.withTime ?? false,
		withWeekDay: formatOptions?.withWeekDay ?? false,
		fallback: formatOptions?.fallback ?? '-',
	};
	const time: Intl.DateTimeFormatOptions = {
		hour12: true,
		hour: formatDateOptions.withTime ? 'numeric' : undefined,
		minute: formatDateOptions.withTime ? 'numeric' : undefined,
	};

	if (formatDateOptions.view === 'long') {
		options = {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			weekday: formatDateOptions.withWeekDay ? 'long' : undefined,
			...time,
		};
	} else {
		options = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			...time,
		};
	}

	if (typeof date === 'number') return new Date(date).toLocaleDateString(locale, options);
	if (date instanceof Date) return date.toLocaleDateString(locale, options);
	if (dayjs.isDayjs(date)) return dayjs().toDate().toLocaleDateString(locale, options);

	return formatDateOptions.fallback;
};

/**
 * Helper function that identifies whether current device's viewport is mobile or not
 * @return {boolean}
 */
export const isMobile = (): boolean => typeof window !== 'undefined' && window.innerWidth < 768;

/**
 * Calculated difference between current date (today) and 'value' in full days
 * @param {DateType} value - date to calculate difference for
 * @return {number}
 */
export const convertDate = (value: number): number => dayjs().subtract(value, 'days').startOf('day').valueOf();
export const getDatesDiff = (value: DateType): number => dayjs().diff(dayjs(value).startOf('day'), 'days');
export const isTablet = (): boolean => typeof window !== 'undefined' && window.innerWidth < 1024;
export const isDesktop = (): boolean => typeof window !== 'undefined' && window.innerWidth > 1200;
export const getFiltersDate = (value: number | undefined):number | undefined => (value !== undefined ? convertDate(value) : undefined);

export const withZeroNumberValidator = Yup.number().integer().moreThan(-1).required('Required field');

/**
 * Return expiration color code based on passed time
 * @param {ExpirationColoring[]} colorTags - an array of preferences to choose from
 * @param {number} daysDiff - how much time has passed
 * @return {string | undefined}
 */
export const getColor = (colorTags: Array<ExpirationColoring>, daysDiff: number): string | undefined => {
	for (let i = 0; i < colorTags.length; i++) {
		const elm = colorTags[i];
		if (daysDiff >= elm.days) {
			return elm.color;
		}
	}

	return undefined;
};

export const getDateRange = (colorTags: Array<ExpirationColoring>, color: string | undefined): ResultDatesRange => {
	const res: ResultDatesRange = { from: undefined, to: undefined };

	for (let i = 0; i < colorTags.length; i++) {
		const elm = colorTags[i];
		if (elm.color === color) {
			res.from = elm.days;

			if (i > 0) {
				res.to = colorTags[i - 1].days;
			}
		}

		if (res.from === undefined && colorTags.length) {
			res.to = colorTags[colorTags.length - 1].days;
		}
	}

	return res;
};

/**
 * Type Guard that checks if items are a List
 * @param {Array<T> | List<T>} items - items which type is tested
 * @return {items is List<T>}
 */
export function isList<T>(items: Array<T> | List<T>): items is List<T> {
	return (items as List<T>).list !== undefined;
}

/**
 * Type Guard that checks if items are an array
 * @param {Array<T> | List<T>} items - items which type is tested
 * @return {items is Array<T>}
 */
export function isArray<T>(items: Array<T> | List<T>): items is Array<T> {
	return (items as Array<T>).length !== undefined;
}

/**
 * The function is used to get an exact number of items in array or list.
 * This is a helper function that allows one to skip checks for exact items type (list or array)
 * @param {Array<T> | List<T>} items - a list or array of items
 * @return {number}
 */
export function count<T>(items: Array<T> | List<T>): number {
	if (isList(items)) return items.count;

	return items.length;
}

/**
 * Function converts current filter preset to url search params string
 * @param {Partial<Filters>} params - filters object that needs to be encoded
 * @return {string}
 */
export const encodeSearchParams = (params: Partial<Filters>): string => {
	const parsedParams = Object.keys(params).reduce((acc, key) => {
		if (Object.prototype.toString.call(params[key]) === '[object Object]') {
			acc[key] = JSON.stringify(params[key]);
		} else {
			acc[key] = params[key];
		}

		return acc;
	}, {});

	return stringify(parsedParams, { arrayFormat: 'bracket' });
};

/**
 * Helper function that identifies whether current string is a json object
 * @param {string} value - string to test
 * @return {boolean}
 */
export function isJsonObject(value: string): boolean {
	try {
		return JSON.parse(value).toString() === '[object Object]';
	} catch (e) {
		return false;
	}
}

/**
 * Function converts url search params into a Filters object
 * @param {string} params - a url encoded filters object
 * @return {Partial<Filters>}
 */
export const decodeSearchParams = (params: string): Partial<Filters> => {
	const parsedParams: Partial<Filters> = parse(params, { arrayFormat: 'bracket', parseNumbers: true });

	return Object.keys(parsedParams).reduce((acc, key) => {
		if (isJsonObject(parsedParams[key])) {
			acc[key] = JSON.parse(parsedParams[key].toString());
		} else {
			acc[key] = parsedParams[key];
		}

		return acc;
	}, {});
};

/**
 * Converts an object of extended type T to object of type T by removing all of its keys that do not fit filter function
 * @param {T} object - an object that needs to be filtered
 * @param {(key: string) => boolean} filter - a function that tests whether current key should be included into final object
 * @return {T}
 */
export const filterObject = <
	T extends Record<string, unknown>,
>(object: T, filter: (key: keyof T) => boolean): T => {
	return Object.keys(object)
		.filter(filter)
		.reduce((obj: Partial<T>, key: keyof T) => {
			obj[key] = object[key];

			return obj;
		}, {}) as T;
};

/**
 * Leaves only those fields of 'filters' that are valid filter options
 * @param {Record<string, unknown>} filters - an object containing both filters options and other fields
 * @return {Record<string, unknown>}
 */
export const getPageFilters = (filters: Record<string, unknown>): Partial<Filters> => {
	return filterObject(filters, (key: string) => (filterKeys as Array<string>).includes(key));
};

/**
 * Leaves only those fields of 'filters' that are not valid filter options
 * @param {Record<string, unknown>} filters - an object containing both filters options and other fields
 * @return {Record<string, unknown>}
 */
export const getSideFilters = (filters: Record<string, unknown>): Record<string, unknown> => {
	return filterObject(filters, (key: string) => !(filterKeys as Array<string>).includes(key));
};

/**
 * Transforms Antd sorter props to ones we send to server
 * @param {SorterResult<T>|undefined} sorter - an object containing props from ant table sorter
 * @return {Sorter}
 */
export const transformSorter = (sorter): Sorter => {
	return {
		column: sorter?.column ? [{
			caption: sorter.field,
			direction: sorter.order === 'descend'
				? SortingDirection.Descending
				: SortingDirection.Ascending,
		}] : [],
	};
};
