import { WithId } from '@common/typescript/objects/WithId';
import { WithRemoved } from '@common/typescript/objects/WithRemoved';
import { isPresent, Nullable } from '@common/typescript/objects/Nullable';

import { InventoryItem } from '@app/objects/Inventory';
import { Price } from '@app/objects/Price';
import { PetPrice } from '@app/objects/Pet';
import { WithOrder } from '@app/objects/WithOrder';

export interface StoreEntryNode extends WithId, WithOrder {
	avatar: string;
	originalAvatar: string;

	name: string;
	fullName: string;

	entry: Nullable<StoreEntry>;
	entryId: number;

	value: Nullable<StoreEntryAttributeValue>;
	valueId: Nullable<number>;

	item: InventoryItem;
	itemId: number;

	parent: Nullable<StoreEntryNode>;
	parentId: Nullable<number>;
	children: Array<StoreEntryNode>;

	removed: boolean;
	disabled: boolean;

	isDefault: boolean;
}

export interface StoreEntry extends WithId {
	name: string;
	isSpecial: boolean;

	avatar: string;
	originalAvatar: string;

	attributes: Array<StoreEntryAttribute>;
	tree: Array<StoreEntryNode>;
}

export interface StoreEntryView extends WithId {
	name: string;
	isSpecial: boolean;
	isDefault: boolean;

	avatar: string;
	originalAvatar: string;

	attributes: Array<StoreEntryAttributeView>;
}

export interface StoreEntryAttributeView extends WithId, WithOrder {
	id: number;
	name: string;
	clinicCanPick: boolean;
}
export interface StoreEntryAttribute extends WithId, WithRemoved, WithOrder {
	name: string;
	clinicCanPick: boolean;
	entry?: StoreEntry;
	entryId?: number;
	values: Array<StoreEntryAttributeValue>;
}

export interface StoreEntryAttributeValue extends WithId, WithRemoved, WithOrder {
	name: string;
	attribute: Nullable<StoreEntryAttribute>;
	attributeId: number;
}

export interface StoreEntryVariant extends WithId, WithRemoved {
	attributeValue: Nullable<StoreEntryAttributeValue>;
	attributeValueId: number;
	item: Nullable<InventoryItem>;
	itemId: number;
}

export interface AvailableStoreEntry extends WithId {
	avatar: string;
	originalAvatar: string;

	storeEntry: StoreEntry;
	prices: Array<Price>;
}

export interface StoreEntryPickValue extends WithId, WithRemoved {
	storeEntryPickId: number;
	valueId: number; // id of StoreEntryAttributeValue
	value: Nullable<StoreEntryAttributeValue>;
}

export interface StoreEntryPick extends WithId {
	petPriceId: number;
	petPrice: Nullable<PetPrice>;
	values: Array<StoreEntryPickValue>;
}

export interface StoreEntryAttributeTemplate extends WithId {
	name: string;
	clinicCanPick: boolean;
	removed: boolean;
	values?: Array<StoreEntryAttributeValueTemplate>;
}

export interface StoreEntryAttributeValueTemplate extends WithId, WithOrder {
	name: string;
	attributeId: number;
	removed: boolean;
}

export function getRoot(entry: StoreEntry): Nullable<StoreEntryNode> {
	const root = entry.tree.find((node: StoreEntryNode) => node.parentId === null);

	return root ?? null;
}

export function getSubTree(entry: Nullable<StoreEntry>, selection: Nullable<StoreEntryNode>): Nullable<StoreEntryNode> {
	if (!entry) return null;
	if (!selection) return null;

	const root = entry.tree.find((node: StoreEntryNode) => node.id === selection.id);
	if (!root) return null;

	const stack: Array<StoreEntryNode> = [root];
	while (stack.length) {
		const node = stack.pop()!;

		node.children = entry.tree.filter((child: StoreEntryNode) => child.parentId === node.id);
		node.children.forEach((child: StoreEntryNode) => {
			child.parent = node;
		});

		stack.push(...node.children);
	}

	return root;
}

export function toTree(entry: StoreEntry): Nullable<StoreEntryNode> {
	const list: Array<StoreEntryNode> = entry.tree;
	if (!list.length) return null;

	const root = getRoot(entry);
	if (!root) return null;

	return getSubTree(entry, root);
}

export function getPath(entry: Nullable<StoreEntry>, selection: StoreEntryNode): Array<StoreEntryNode> {
	if (!entry) return [];

	const path: Array<StoreEntryNode> = [];
	const tree: Array<StoreEntryNode> = entry.tree;

	let node: Nullable<StoreEntryNode> = selection;
	while (node) {
		path.push(node);

		if (node.parentId === null) {
			node = null;
		} else if (node.parent !== null) {
			node = node.parent;
		} else {
			node = tree.find((item: StoreEntryNode) => item.id === node!.parentId);
		}
	}

	return path.reverse();
}

export function getSegments(entry: Nullable<StoreEntry>, selection: StoreEntryNode): Array<string> {
	if (!entry) return [];

	return getPath(entry, selection)
		.map((node: StoreEntryNode) => node.value?.name)
		.filter(isPresent);
}

export function getFullName(entry: Nullable<StoreEntry>, selection: StoreEntryNode): string {
	return getPath(entry, selection)
		.map((node: StoreEntryNode) => {
			if (node.valueId) return node.value?.name;

			return entry?.name;
		})
		.filter(isPresent)
		.join(' ');
}

export function intersect(left: Array<StoreEntryNode>, right: Array<StoreEntryNode>): Array<StoreEntryNode> {
	return left.filter((node: StoreEntryNode) => right.some((item: StoreEntryNode) => node.id === item.id));
}
