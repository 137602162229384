import { EnumType } from '@app/components/Pages/PetEditor/OldPetEditorTabs/ChangeRender/EnumRenderHandler';

export enum ValueChangeKey {
	Count = 'Count',
	Id = 'Id',
	InternalIdNum = 'InternalIdNum'
}

export class KeyManager {
	private readonly _key: string;

	static for(key: string): KeyManager {
		return new KeyManager(key);
	}

	public constructor(key: string) {
		this._key = key;
	}

	public isCount(): boolean {
		return this._key === ValueChangeKey.Count;
	}

	public isId(): boolean {
		return this._key === ValueChangeKey.Id;
	}

	public isEnumGroup(): boolean {
		return (Object.values(EnumType) as Array<string>).includes(this._key);
	}

	public isIntegerGroup(): boolean {
		return this._key === ValueChangeKey.Count || this._key.includes(ValueChangeKey.Id);
	}

	public isInternalIdNum(): boolean {
		return this._key === ValueChangeKey.InternalIdNum;
	}

	public is(oneOf: Array<ValueChangeKey>) {
		return oneOf.some((item: ValueChangeKey) => this._key === item);
	}
}
