import * as React from 'react';

import { Field, FieldProps, useFormikContext } from 'formik';
import { v4 } from 'uuid';

import Table, { ColumnProps } from 'antd/lib/table';
import Button from 'antd/lib/button';
import { DeleteOutlined } from '@ant-design/icons';

import { List } from '@common/typescript/objects/List';
import { Nullable } from '@common/typescript/objects/Nullable';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';
import {
	AlertMessage,
	AlertType,
} from '@common/react/components/UI/AlertMessage/AlertMessage';
import { MobileCell } from '@common/react/components/Pages/ItemsPage';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';

import {
	CardSize,
	Card,
	ListComponent,
	ItemsList,
} from '@app/components/UI/Cards/Card';
import { PetPrice, ServiceType } from '@app/objects/Pet';
import { PriceType, Price } from '@app/objects/Price';
import { useRequest } from '@app/hooks/useRequest';
import { useModal } from '@app/hooks/Editors/useModal';
import { ViewMode } from '@app/components/Utils/Utils';
import { FormValues } from '@app/components/Pages/ClinicPetEditor/types';
import { SpecialServiceDialog } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/SpecialServices/SpecialServiceDialog';
import { BaseSectionProps } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/ViewContent';
import { getAdditionalPrice, getDisableModal, getPetPrice } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/utils';
import { NumberField } from '@app/components/UI/NumberField/NumberField';
import { ServiceIndexProvider } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/ServiceIndexProvider';
import { useCrematory } from '@app/hooks/useCrematory';
import { expendedProps } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/SpecialServiceList';

function getSpecialServices(services: Array<PetPrice>): Array<PetPrice> {
	return services.filter((q: PetPrice) => q.price?.specialServiceId !== null)
		.filter((q: PetPrice) => !q.removed);
}

function getSpecialServicesList(pet: FormValues): Array<ItemsList> {
	const services = getSpecialServices(pet.services);

	return getPetPrice(services);
}

const servicesColumns = (showPricesToClinic?: boolean): Array<ColumnProps<PetPrice>> => TableBuilder.shape<PetPrice>()
	.addColumn({
		title: 'Quantity',
		width: '150px',
		dataIndex: 'count',
		render: (count: number, record: PetPrice) => (
			<MobileCell caption="Quantity" fullWidth>
				<Field name="services">
					{(fieldProps: FieldProps<FormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							containerClassName=""
							render={({ field, form }: FieldProps<Array<PetPrice>, FormValues>) => (
								<NumberField
									{...field}
									value={record.count}
									min={0}
									onChange={(count: number) => {
										const services = field.value.map((item: PetPrice) => {
											if (item.clientId === record.clientId) {
												const batchCount: number = item.price?.batchCount ?? 0;
												const batchPrice: number = item.price?.batchPrice ?? 0;
												const perItem: number = item.price?.value ?? 0;

												const value = batchPrice + Math.max(count - batchCount, 0) * perItem;
												const extra = count <= batchCount ? 0 : (count - batchCount) * perItem;

												return {
													...item,
													count,
													value,
													extra,
												};
											}

											return item;
										});
										form.setFieldValue(field.name as keyof FormValues, services, false);
									}}
								/>
							)}
						/>
					)}
				</Field>
			</MobileCell>
		),
	})
	.addColumn({
		title: 'Name',
		dataIndex: 'name',
		render: (name: string, record: PetPrice) => {
			const batchCount: Nullable<number> = record.price?.batchCount ?? null;
			const batchPrice: Nullable<number> = record.price?.batchPrice ?? null;
			const withBatch = batchCount !== null && batchPrice !== null;

			const batchDescription = withBatch ? `${batchCount} at $${batchPrice}` : null;
			const additionalDescription = `Each additional - $${record.price?.value}`;

			return (
				<MobileCell caption="Name" fullWidth>
					<div>
						<div>{name}</div>
						{showPricesToClinic && withBatch && batchCount! > 0
							? <div className="special-service-description">{batchDescription}</div> : null}
						{showPricesToClinic && record.price ? <div className="special-service-description">{additionalDescription}</div> : null}
					</div>
				</MobileCell>
			);
		},
	})
	.addColumn({
		title: <b>Notes</b>,
		dataIndex: 'note',
		render: (_, record: PetPrice) => (
			<ServiceIndexProvider clientId={record.clientId}>
				{
					(index: Nullable<number>) => {
						if (index === null) return <div>-</div>;

						return (
							<Field name={`services.${index}.note`}>
								{
									({ field, form, meta }) => (
										<div className="row">
											<FormikInput
												fieldProps={{ field, form, meta }}
												inputProps={{ disabled: !record.price?.specialService?.hasNote || record.done }}
												containerClassName="col-sm-12"
											/>
										</div>
									)
								}
							</Field>
						);
					}
				}
			</ServiceIndexProvider>
		),
	})
	.addColumn({
		title: '',
		dataIndex: 'actions',
		render: (_, record: PetPrice) => (
			<MobileCell caption="Actions" fullWidth>
				<Field name="services">
					{({ field, form }: FieldProps<Array<PetPrice>, FormValues>) => (
						<Button
							// className="btn btn-transparent color-red"
							title="Delete"
							type="text"
							danger
							icon={<DeleteOutlined />}
							onClick={() => {
								let values: Array<PetPrice> = [];

								if (record.id <= 0) {
									values = field.value.filter((q: PetPrice) => q.clientId !== record.clientId);
								} else {
									values = field.value.map((q: PetPrice) => {
										if (q.id === record.id) {
											return {
												...q,
												removed: true,
											};
										}

										return q;
									});
								}

								form.setFieldValue(field.name as keyof FormValues, values, false);
							}}
						/>
					)}
				</Field>
			</MobileCell>
		),
	})
	.build();

interface DefaultService {
	clientId: string;
	name: string;

	count: number;
	value: number;
	extra: number;

	priceId: number;
	price: Price;

	batchCount: number;
	batchPrice: number;

	note: string;
}

interface DefaultServicesProps {
	id: number;
	crematoryId: number;
	serviceType: number;

	onChange: (field: string, list: Array<PetPrice>, validate: boolean) => void;
	onLoad: (value: boolean) => void;
	onError: (error: Nullable<string>) => void;
}

interface AvailablePriceListParams {
	priceType: PriceType;
	serviceType: ServiceType;

	withSpecialService: boolean;
	withMyClinic: boolean;
	specialService: boolean;
	isDefault: boolean;

	count: number;
}

interface RequestParams {
	clinicId: Nullable<number>;
	priceType: PriceType;
	serviceType?: ServiceType;
	withSpecialService: boolean;
	count: number;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const DefaultServicesTracker: React.VFC<DefaultServicesProps> = (props: DefaultServicesProps) => {
	const request = useRequest<List<Price>, AvailablePriceListParams>('availablePriceList', undefined, { requestOnMount: false });
	const prevCrematoryId = React.useRef<number>(props.id > 0 ? props.crematoryId : -1);
	const prevServiceType = React.useRef<Nullable<ServiceType>>(props.id > 0 ? props.serviceType : null);

	React.useEffect(() => {
		if (props.crematoryId === -1 || props.serviceType === undefined) return;

		if (props.crematoryId !== prevCrematoryId.current || props.serviceType !== prevServiceType.current) {
			prevCrematoryId.current = props.crematoryId;
			prevServiceType.current = props.serviceType;

			request.reload({
				priceType: PriceType.Wholesale,
				serviceType: props.serviceType,

				withSpecialService: true,
				withMyClinic: true,
				specialService: true,
				isDefault: true,

				count: 1000,
			});
		}
	}, [props.crematoryId, props.serviceType]);

	React.useEffect(() => {
		if (request.item !== null) {
			const services: Array<PetPrice> = (request.item?.list ?? [])
				.map((item: Price) => ({
					clientId: v4(),
					name: item.specialService?.name ?? 'Special Service',

					count: item.batchCount || 1,
					value: item.batchCount > 1 ? item.value : item.batchPrice,
					extra: item.batchCount > 0 ? 0 : item.value,

					priceId: item.id,
					price: item,

					batchCount: item.batchCount,
					batchPrice: item.batchPrice,

					pickupService: null,
					pickupServiceId: null,

					node: null,
					nodeId: null,

					note: item.specialService?.defaultNote ?? '',
				})).map((item: DefaultService) => ({
					id: -1,
					clientId: item.clientId,
					name: item.name,

					petId: props.id,
					pet: null,

					priceId: item.priceId,
					price: item.price,

					count: item.count,
					completedCount: 0,
					done: false,

					value: item.value,
					extra: item.extra,
					tax: 0,
					discount: 0,

					editor: null,
					editorId: null,

					pickupService: null,
					pickupServiceId: null,

					batchCount: item.batchCount,
					batchPrice: item.batchPrice,

					node: null,
					nodeId: null,

					note: item.note ?? '',
				}));

			props.onChange('services', services, false);
		}
	}, [request.item, props.onChange]);

	React.useEffect(() => {
		props.onLoad(request.loading);
	}, [request.loading, props.onLoad]);

	React.useEffect(() => {
		props.onError(request.error);
	}, [request.error, props.onError]);

	return null;
};

export const SpecialServices: React.FC<BaseSectionProps> = (props: BaseSectionProps) => {
	const { values } = useFormikContext<FormValues>();
	const modal = useModal(false);
	const crematory = useCrematory();
	const showPricesToClinic = crematory?.showPricesToClinic;
	const [loading, setLoading] = React.useState<boolean>(() => false);
	const [error, setError] = React.useState<Nullable<string>>(() => null);
	const [prices, setPrices] = React.useState<Array<Price>>([]);
	const request = useRequest<List<Price>, RequestParams>('priceList', undefined, { requestOnMount: false });

	const requestParams = React.useMemo(() => ({
		clinicId: null,
		priceType: PriceType.Wholesale,
		serviceType: values.serviceType,
		withSpecialService: true,
		count: 100,
	}), [values.serviceType]);

	React.useEffect(() => {
		if (!requestParams.serviceType) return;

		request.reload(requestParams)
			.then((response: List<Price> | void) => {
				if (!response) return;

				const priceList = response.list.filter((price: Price) => price.specialServiceId)
					.sort((a: Price, b: Price) => a.to - b.to);

				setPrices(priceList);
			});
	}, [requestParams]);

	return (
		<Card
			name="Special Services and Memorial Items"
			size={CardSize.Medium}
			mode={props.mode}

			fullWidth
			withBtn={props.mode === ViewMode.edit}
			onClick={modal.open}
			disabled={getDisableModal(values.serviceType)}

			editContent={(
				<Field name="services">
					{({ field, form }: FieldProps<Array<PetPrice>, FormValues>) => (
						<>
							<DefaultServicesTracker
								id={form.values.id}
								crematoryId={props.crematoryId}
								serviceType={form.values.serviceType!}

								onChange={form.setFieldValue}
								onLoad={setLoading}
								onError={setError}
							/>
							<Table
								columns={servicesColumns(showPricesToClinic)}
								dataSource={getSpecialServices(field.value)}
								pagination={{ hideOnSinglePage: true }}
								rowKey={(record: PetPrice) => (values.id > 0 ? record.id.toString() : record.clientId)}
								className="table-mobile view-card-table view-card-table__short"
								loading={loading}
								{...expendedProps(values.services?.filter((q: PetPrice) => !q.removed) ?? [], prices)}
							/>
							{showPricesToClinic && (
								<div className="additional-amount">
									<span>
										Additional amount: ${getAdditionalPrice(getSpecialServices(field.value)).toFixed(2)}
									</span>
								</div>
							)}
							<AlertMessage type={AlertType.Danger} message={error} />
							<Field name="services">
								{({ field, form }: FieldProps<Array<PetPrice>, FormValues>) => (
									<SpecialServiceDialog
										petId={form.values.id}
										serviceType={form.values.serviceType!}
										priceType={PriceType.Wholesale}

										value={getSpecialServices(field.value)}
										onChange={(values: Array<PetPrice>) => form.setFieldValue(field.name as keyof FormValues, values, false)}

										state={modal.state}
										close={modal.close}
									/>
								)}
							</Field>
						</>
					)}
				</Field>
			)}

			viewContent={(
				<Field name="">
					{({ form }: FieldProps<FormValues>) => (
						<ListComponent
							list={getSpecialServicesList(form.values)}
							placeholder="There are no special services or memorabilia items"
						/>
					)}
				</Field>
			)}
		/>
	);
};
