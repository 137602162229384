import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { useDragDropArea } from '@app/components/Interaction/DragDrop/DragDropArea';

export interface DragItem {
	index: number;
}

interface IDragDrop {
	ref: React.RefObject<HTMLDivElement>;
	setCanDrag: (value: boolean) => void;
}

export const useDragDrop = (
	index: number,
	key: string,
	onReorder?: (from: number, to: number) => void,
): IDragDrop => {
	const [canDrag, setCanDrag] = React.useState<boolean>(() => false);
	const ref = React.useRef<HTMLDivElement>(null);
	const hovered = useDragDropArea();

	const [, dragRef] = useDrag(() => ({
		type: key,
		item: { index },
		canDrag,
	}), [canDrag]);
	const [, dropRef] = useDrop<DragItem, void>(() => ({
		accept: key,
		hover: (item: DragItem) => {
			const from = item.index;
			const to = index;

			// Do not change with itself
			if (from === to) return;
			// Do not trigger onReorder more than once per card
			if (hovered.current.get(key) === to) return;

			onReorder?.(from, to);
			hovered.current.set(key, to);
			item.index = index;
		},
		drop: () => {
			hovered.current.delete(key);
		},
	}), [index, onReorder]);

	dragRef(dropRef(ref));

	return {
		ref,
		setCanDrag,
	};
};
