/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import clsx from 'clsx';
import Popover from 'antd/lib/popover';
import Button from 'antd/lib/button';
import {
	BankOutlined,
	CompassOutlined,
	DollarOutlined,
	PercentageOutlined,
	PlusOutlined,
	RestOutlined,
	ShoppingCartOutlined,
	SignatureOutlined,
	ThunderboltOutlined,
	TruckOutlined,
} from '@ant-design/icons';

import { Nullable, Optional } from '@common/typescript/objects/Nullable';

import { getUrnFullName, iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import { PetPrice } from '@app/objects/Pet';
import { DiscountType, PriceKind, PriceType } from '@app/objects/Price';
import { Translator } from '@app/internationalization/init';
import { addressService } from '@app/services/AddressService';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { ApplicationState } from '@app/store';

import { IPriceStack } from '@app/services/pricing/IPriceStack';
import { getOrderSuffix } from '@app/utilities/naturalLanguage';
import { SpecialServiceType } from '@app/objects/SpecialService';
import { TaxPolicy } from '@app/objects/Crematory';
import { useCrematory } from '@app/hooks/useCrematory';
import { InvoiceTaxModel } from '@app/objects/Invoice';

const localeKey = 'editors.pet-editor.price-tag';
interface TotalContentProps {
	value: IPriceStack;
	formValues: PetFormValues;
	onRecalculate: () => void;
}

function isMobileView(): boolean {
	return typeof window !== 'undefined' && window.innerWidth < 400;
}

function getDiscountLabel(discount: PetPrice, t: Translator, isClinicDiscount: boolean = false): string {
	const price = discount.price;
	const title = isClinicDiscount ? discount.name : t('discount');

	if (!price || price.unit !== DiscountType.Percentage) return `${title}`;

	return `${title} (${price.value}%)`;
}

function getProductValue(value: number) {
	if (value >= 0) return `$${value.toFixed(2)}`;

	return `-$${(value * -1).toFixed(2)}`;
}

function getServiceLabel(service: PetPrice) {
	if (service.price?.priceKind === PriceKind.PickupPrice) return service.name;

	return `${service.name} x ${service.count}`;
}

interface TaxesProps {
	serviceTax: Nullable<number>;
	serviceTaxPercentage: number;
	productTax: Nullable<number>;
	productTaxPercentage: number;
	formValues: PetFormValues;
	perItem: boolean;
}

interface ClinicDiscountsProps {
	discounts: Nullable<Array<PetPrice>>;
	perItem: boolean;
}

function formatPercentage(value: Optional<number>): string {
	if (!value) return '0';

	return (value * 100).toFixed(0);
}

const Taxes: React.FC<TaxesProps> = (props: TaxesProps) => {
	const { t } = useTranslation('translation', { keyPrefix: localeKey });
	const { values } = useFormikContext<PetFormValues>();
	const crematories = useSelector((state: ApplicationState) => state.crematories.items);
	const crematory = crematories.find((item) => item.id === props.formValues.crematoryId);

	if (!props.serviceTax && !props.productTax) return null;

	const details = addressService.provide(crematory.country);
	const taxName = t(details.taxKey);

	if (props.serviceTaxPercentage === props.productTaxPercentage) {
		const value = (props.serviceTax ?? 0) + (props.productTax ?? 0);

		return (
			<tr>
				<td className="icon-container"><BankOutlined /></td>
				<td>{taxName} ({formatPercentage(props.productTaxPercentage)}%):</td>
				{props.perItem ? <td colSpan={3} /> : null}
				<td className="text-right">
					${value.toFixed(2)}
				</td>
			</tr>
		);
	}

	const taxPolicy = values.priceType === PriceType.Retail ? crematory.retailTaxPolicy : crematory.wholesaleTaxPolicy;
	const serviceTaxName = taxPolicy === TaxPolicy.TaxExcluded ? 'Service' : 'Including Service';
	const productTaxName = taxPolicy === TaxPolicy.TaxExcluded ? 'Product' : 'Including Product';

	return (
		<>
			<tr>
				<td className="icon-container"><BankOutlined /></td>
				<td>{serviceTaxName} {taxName} ({formatPercentage(props.serviceTaxPercentage)}%):</td>
				{props.perItem ? <td colSpan={3} /> : null}
				<td className="text-right">
					${(props.serviceTax ?? 0).toFixed(2)}
				</td>
			</tr>
			<tr>
				<td className="icon-container"><BankOutlined /></td>
				<td>{productTaxName} {taxName} ({formatPercentage(props.productTaxPercentage)}%):</td>
				{props.perItem ? <td colSpan={3} /> : null}
				<td className="text-right">
					${(props.productTax ?? 0).toFixed(2)}
				</td>
			</tr>
		</>
	);
};

const ClinicDiscounts: React.FC<ClinicDiscountsProps> = (props: ClinicDiscountsProps) => {
	const { t } = useTranslation('translation', { keyPrefix: localeKey });
	const basePriceDiscount = props.discounts?.find((i) => i.price?.applyTo === PriceKind.BasePrice);
	const servicePriceDiscount = props.discounts?.find((i) => i.price?.applyTo === PriceKind.SpecialServicePrice);

	return (
		<>
			{
				basePriceDiscount
					? (
						<tr>
							<td className="icon-container"><PercentageOutlined /></td>
							<td>{getDiscountLabel(basePriceDiscount, t, true)}:</td>
							{props.perItem ? <td colSpan={3} /> : null}
							<td className="text-right">
								${basePriceDiscount.value.toFixed(2)}
							</td>
						</tr>
					) : null
			}
			{
				servicePriceDiscount ? (
					<tr>
						<td className="icon-container"><PercentageOutlined /></td>
						<td>{getDiscountLabel(servicePriceDiscount, t, true)}:</td>
						{props.perItem ? <td colSpan={4} /> : null}
						<td className="text-right">
							${servicePriceDiscount.value.toFixed(2)}
						</td>
					</tr>
				) : null
			}
		</>
	);
};

function filterServices(services: Array<PetPrice>): Array<PetPrice> {
	return services.filter((q: PetPrice) => {
		const type = q.price?.specialService?.specialServiceType;

		return type === SpecialServiceType.Kit || type === SpecialServiceType.Service;
	});
}

function formatMoney(value: Optional<number>): string {
	if (!value) return '';

	return `$${value.toFixed(2)}`;
}

function formatTotal(price: Optional<PetPrice>): string {
	if (!price) return '';

	let value = price.value - (price.discount ?? 0);
	if (price.price?.priceKind === PriceKind.BasePrice) {
		value += price.extra;
	}

	return `$${value.toFixed(2)}`;
}

interface PerItemContentProps {
	perItem: boolean;
	service: Optional<PetPrice>;
}

const PerItemContent: React.FC<PerItemContentProps> = (props: PerItemContentProps) => {
	if (!props.perItem || !props.service) return null;

	return (
		<>
			<td className="text-right">
				{formatMoney(props.service.discount)}
			</td>
			<td className="text-right">
				{formatMoney(props.service.tax)}
			</td>
			<td className="text-right">
				{(formatTotal(props.service))}
			</td>
		</>
	);
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const TotalContent: React.FC<TotalContentProps> = (props: TotalContentProps) => {
	const { t } = useTranslation('translation', { keyPrefix: localeKey });
	const crematory = useCrematory(props.formValues.crematoryId);
	const perItem: boolean = crematory?.invoiceTaxModel === InvoiceTaxModel.TaxPerItem;
	const details = addressService.provide(crematory?.country);
	const taxName = t(details.taxKey);

	const cremation = props.value.base;
	const engraving = props.value.engravings;
	const rush = props.value.rush;
	const priceExtra = cremation && cremation.extra > 0 ? ` + $${cremation.extra.toFixed(2)}` : '';
	const services = filterServices(props.value.services).filter((service: PetPrice) => !service.removed);

	const urns = props.value.urns;
	const products = props.value.products;

	const delivery = props.value.delivery;
	const pickup = props.value.pickup;

	const discount = props.value.discount;
	const clinicDiscounts = props.value.clinicDiscounts;

	const subtotal = props.value.subtotal;
	const total = props.value.total;

	return (
		<table className="table price-widget-table">
			<thead>
				<tr>
					<td
						className="icon-container"
					/>
					<td colSpan={perItem ? 4 : 1} />
					<td className="pull-right">
						<Button
							danger
							size="small"
							onClick={props.onRecalculate}
						>
							Recalculate
						</Button>
					</td>
				</tr>
				{
					perItem
						? (
							<tr style={{ opacity: 0.6 }}>
								<td className="icon-container" />
								<td><strong>Item</strong></td>
								<td className="text-right"><strong>Price</strong></td>
								<td className="text-right"><strong>Discount</strong></td>
								<td className="text-right"><strong>{taxName} Amount</strong></td>
								<td className="text-right"><strong>Total Amount</strong></td>
							</tr>
						) : null
				}
			</thead>

			<tbody>
				<tr>
					<td className="icon-container">
						<DollarOutlined />
					</td>
					<td>
						{t('price')}:
					</td>
					<td className="text-right">
						{
							cremation
								? `$${cremation.value.toFixed(2)}${(priceExtra)}`
								: <span className="color-red">{t('price-not-found')}</span>
						}
					</td>
					<PerItemContent
						service={cremation}
						perItem={perItem}
					/>
				</tr>
				{
					engraving.map((item: PetPrice, index: number) => (
						<tr key={item.clientId}>
							<td className="icon-container"><SignatureOutlined /></td>
							<td>Engraving - {index + 1}{getOrderSuffix(index + 1)} line</td>
							<td className="text-right">
								{item.value ? (`$${item.value.toFixed(2)}`) : t('free')}
							</td>
							<PerItemContent
								service={item}
								perItem={perItem}
							/>
						</tr>
					))
				}
				{
					rush && props.formValues.rush
						?	(
							<tr>
								<td className="icon-container"><ThunderboltOutlined /></td>
								<td>Rush:</td>
								<td className="text-right">
									{`$${rush.value.toFixed(2)}`}
								</td>
								<PerItemContent
									service={rush}
									perItem={perItem}
								/>
							</tr>
						) : null
				}
				{
					services.map((service: PetPrice) => (
						<tr className="special-service" key={service.clientId}>
							<td className="icon-container">
								<PlusOutlined />
							</td>
							<td>{getServiceLabel(service)}:</td>
							<td className="text-right">
								{service.value ? (`$${service.value.toFixed(2)}`) : t('free')}
							</td>
							<PerItemContent
								service={service}
								perItem={perItem}
							/>
						</tr>
					))
				}
				{
					urns.map((urn: PetPrice) => (
						<tr key={urn?.clientId}>
							<td className="icon-container"><RestOutlined /></td>
							<td>{getUrnFullName(urn)} x {urn.count}: </td>
							<td className="text-right">
								{getProductValue(urn.value)}
							</td>
							<PerItemContent
								service={urn}
								perItem={perItem}
							/>
						</tr>
					))
				}
				{
					products.map((product) => (
						<tr key={product?.clientId}>
							<td className="icon-container"><ShoppingCartOutlined /></td>
							<td>{product.name} x {product.count}: </td>
							<td className="text-right">
								{getProductValue(product.value)}
							</td>
							<PerItemContent
								service={product}
								perItem={perItem}
							/>
						</tr>
					))
				}
				<ClinicDiscounts
					discounts={clinicDiscounts}
					perItem={perItem}
				/>
				{
					discount
						? (
							<tr>
								<td className="icon-container"><PercentageOutlined /></td>
								<td>{getDiscountLabel(discount, t)}:</td>
								{perItem ? <td colSpan={3} /> : null}
								<td className="text-right">
									${discount.value.toFixed(2)}
								</td>
							</tr>
						) : null
				}
				{
					pickup
						? (
							<tr className="special-service" key={pickup.clientId}>
								<td className="icon-container">
									<CompassOutlined />
								</td>
								<td>{getServiceLabel(pickup)}:</td>
								<td className="text-right">
									{pickup.value ? (`$${pickup.value.toFixed(2)}`) : t('free')}
								</td>
								<PerItemContent
									service={pickup}
									perItem={perItem}
								/>
							</tr>
						) : null
				}
				{
					delivery
						?	(
							<tr>
								<td className="icon-container"><TruckOutlined /></td>
								<td>Delivery:</td>
								<td className="text-right">
									{`$${delivery.value.toFixed(2)}`}
								</td>
								<PerItemContent
									service={delivery}
									perItem={perItem}
								/>
							</tr>
						) : null
				}
				{
					subtotal
						? (
							<tr>
								<td className="icon-container" />
								<td><strong>{t('subtotal')}:</strong></td>
								{perItem ? <td colSpan={3} /> : null}
								<td className="text-right">
									<strong>
										${subtotal.toFixed(2)}
									</strong>
								</td>
							</tr>
						) : null
				}
			</tbody>

			<tfoot>
				<Taxes
					serviceTax={props.value.serviceTaxTotal}
					serviceTaxPercentage={props.value.serviceTaxPercentage}
					productTax={props.value.productTaxTotal}
					productTaxPercentage={props.value.productTaxPercentage}
					formValues={props.formValues}
					perItem={perItem}
				/>
				<tr>
					<td className="icon-container" />
					<td><strong>{t('total')}:</strong></td>
					{perItem ? <td colSpan={3} /> : null}
					<td className="text-right">
						<strong>
							${total.toFixed(2)}
						</strong>
					</td>
				</tr>
			</tfoot>
		</table>
	);
};

export const PriceTagDisplay: React.FC<TotalContentProps> = (props: TotalContentProps) => {
	const { t } = useTranslation();

	const isMobile = isMobileView();
	const classes = clsx('pet-editor-page__total', !props.value.base && 'pet-editor-page__total_not-found');

	return (
		<Popover
			trigger={isMobile ? 'click' : 'hover'}
			placement={isMobile ? 'bottomRight' : 'leftTop'}
			content={(
				<TotalContent
					value={props.value}
					onRecalculate={props.onRecalculate}
					formValues={props.formValues}
				/>
			)}
		>
			<div
				className={classes}
			>
				{t(iKey('price-tag.total'))}: $
				{props.value.total.toFixed(2)}
			</div>
		</Popover>
	);
};
